import { StatusColor, Status as StatusType, statusText } from 'utils/types';
import { Status } from 'components/shared/status';
import { Box } from '@mui/material';

type Props = {
  status: StatusType;
  children?: string | null;
};

export const StatusChip = ({ status, children }: Props) => {
  return (
    <Status sx={{ borderRadius: 6 }} severity={StatusColor[status]}>
      <Box mt="-1px">{`${statusText[status]} ${children}`}</Box>
    </Status>
  );
};
