import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { Member } from 'hooks/useMember';

export const useSchoolMembers = (schoolId: number) => {
  const membersResponse = useQuery<Member[], AxiosError>(['members'], async () => {
    const response = await axios.get('/admin/members', { headers: { school: schoolId } });
    return response.data;
  });

  return membersResponse;
};
