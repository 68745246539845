import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { Enrolment } from 'hooks/useMember';

export const useUserEnrolments = (schoolId: number) => {
  const enrolmentsResponse = useQuery<Enrolment[], AxiosError>(['user-enrolments'], async () => {
    const response = await axios.get('/admin/enrolments', { headers: { school: schoolId } });
    return response.data;
  });

  return enrolmentsResponse;
};
