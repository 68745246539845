import { Button, CircularProgress } from '@mui/material';
import { Enrolment, UpdateEnrolmentDto } from 'hooks/useMember';
import { useMutate } from 'hooks/useMutate';
import { User } from 'hooks/useUser';
import { useQueryClient } from 'react-query';
import { Status, ResumableStatus } from 'utils/types';

export const UpdateButton = ({ user, enrolment }: { user: User; enrolment: Enrolment }) => {
  const queryClient = useQueryClient();
  const { update } = useMutate<UpdateEnrolmentDto>('enrolment/cancel-subscription', {
    onSuccess: () => {
      queryClient.invalidateQueries(['enrolment']);
      queryClient.invalidateQueries(['member']);
    },
  });
  return (
    <>
      {enrolment.loading ? (
        <CircularProgress size={22} sx={{ mb: -3 }} />
      ) : (
        <>
          {enrolment.status !== Status.TRIALING && (
            <Button
              onClick={() => {
                update({
                  userId: user.id,
                  enrolment: enrolment,
                });
                enrolment.loading = true;
              }}
            >
              {(enrolment.cancelDate || enrolment.resumesAt) && ResumableStatus.includes(enrolment.status)
                ? 'Resume'
                : 'Cancel'}
            </Button>
          )}
        </>
      )}
    </>
  );
};
