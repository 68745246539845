import { Box, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';

export const CohortBadge = ({ colors, sx }: { colors: string[]; sx?: SxProps }) => (
  <Box
    display={'flex'}
    sx={{
      border: '1px solid',
      borderColor: grey[300],
      height: 40,
      width: 40,
      borderRadius: '50%',
      overflow: 'hidden',
      ...sx,
    }}
  >
    {colors.map((color, idx) => (
      <Box key={idx} sx={{ display: 'flex', flexGrow: 1, height: 1, bgcolor: color }} />
    ))}
  </Box>
);
