import axios, { AxiosError } from 'axios';
import { UseQueryResult, useQuery } from 'react-query';
import { Location } from 'utils/types';
import { useSession } from './useSession';
import { Subject } from './useSubject';

export type CreateClosure = {
  id?: number;
  locations: number[];
  subjects: number[];
  description: string;
  startDate: string;
  endDate: string;
  repeating: boolean;
};

export type Closure = {
  id: number;
  locations: Location[];
  subjects: Subject[];
  description: string;
  startDate: string;
  endDate: string;
  repeating: boolean;
};

export const useClosure = (schoolId: number): UseQueryResult<Closure[]> => {
  const { session } = useSession();

  return useQuery<any, AxiosError>(
    ['closure'],
    async () => {
      const response = await axios.get(`/class/closure`, {
        headers: { school: schoolId },
      });
      return response.data;
    },
    {
      enabled: session,
    }
  );
};
