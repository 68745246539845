import { Avatar, Box, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { PageType, Tabs } from '../../utils/types';
import { useEffect, useRef, useState } from 'react';
import { useSchoolContext } from '../context/school-context';
import { useQueryState } from '../../hooks/useQueryState';
import { grey } from '@mui/material/colors';
import { TabButton } from './tab-button';
import logo from '../../assets/mm-logo.svg';
import { shadeColor, THEME } from 'Theme';
import { useUserContext } from 'components/context/user-context';
import { DisplayImage } from 'components/shared/display-image';
import { useBrandingContext } from 'components/context/branding-context';

export const Header = () => {
  const [query, setQuery] = useQueryState();
  const { secColor } = useBrandingContext();
  const { school } = useSchoolContext();
  const { user } = useUserContext();
  const [menu, setMenu] = useState(false);
  const schoolRef = useRef(null);

  useEffect(() => {
    const defaultTab = Tabs[query.page as PageType]?.[0];
    if (!query.page) {
      setQuery({ page: PageType.home });
    }
    if (defaultTab && !query[`${query.page} tab`]) {
      setQuery({ [`${query.page} tab`]: defaultTab });
    }
  });

  const handleSchoolChange = (uuid = '') => {
    location.href = `${location.origin}/${uuid}`;
    setMenu(false);
  };

  return (
    <Grid container>
      <Grid
        container
        sx={{
          boxShadow: `1px 1px 2px ${grey[500]}`,
          background: `linear-gradient(to right, ${secColor}, ${shadeColor(secColor, -60)})`,
          zIndex: 999,
        }}
      >
        <Grid container minHeight={50}>
          <Box ml={2} display={'flex'}>
            <Avatar
              ref={schoolRef}
              onClick={() => setMenu(true)}
              src={school.logo || logo}
              sx={{ m: 'auto', width: 40, height: 40 }}
            />
            {user.members.length > 1 && (
              <Menu
                id="user-menu"
                anchorEl={schoolRef.current}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={menu}
                onClick={() => setMenu(false)}
              >
                {user.members.map(m => (
                  <MenuItem
                    key={m.school?.id}
                    sx={{ minWidth: '150px', display: 'flex' }}
                    onClick={() => handleSchoolChange(m.school?.uuid)}
                  >
                    <Avatar
                      src={m.school?.logo}
                      sx={{ width: 30, height: 30, mr: 2, boxShadow: `1px 2px 2px ${'#00000089'}` }}
                    />
                    {m.school?.name}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Box>
          <Box display={'flex'} flexGrow={1} justifyContent={'center'}>
            <Typography variant="h2" m={'auto'} color={THEME.palette.getContrastText(school.secColor)}>
              {query.page}
            </Typography>
          </Box>
          <Box display={'flex'}>
            <DisplayImage
              sx={{ marginY: 'auto', m: 'auto', mr: 2 }}
              firstName={user.firstName}
              picture={user.picture}
              size={40}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'} height={30} zIndex={999}>
        <Box width={1} bgcolor={school.priColor} display={'flex'} justifyContent={'center'}>
          {Tabs[query.page as PageType]?.map(t => (
            <TabButton
              key={t}
              onClick={() => setQuery({ [`${query.page} tab`]: t })}
              name={t}
              activeTab={query[`${query.page} tab`]}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
