import { MenuItem, TextField } from '@mui/material';
import { SubjectModal } from 'components/modals/subject-modal';
import { useSubject } from 'hooks/useSubject';
import { useSchoolContext } from '../context/school-context';

type Props = {
  subjects: number[];
  setSubjects: (s: number[]) => void;
  create?: boolean;
  placeholder?: string;
  multi?: boolean;
};

export const SubjectSelect = ({ subjects, setSubjects, placeholder, create = true, multi = true }: Props) => {
  const { school } = useSchoolContext();
  const { subjectsResponse } = useSubject(school.id);

  return (
    <>
      <SubjectModal
        open={subjects.includes(0)}
        close={i => setSubjects(subjects.map(s => (s === 0 && i ? i : s)).filter(s => s))}
      />
      <TextField
        label={placeholder || 'subjects'}
        select
        SelectProps={{
          fullWidth: true,
          multiple: multi,
          value: subjects,
          onChange: i => setSubjects(multi ? (i.target.value as number[]) : [i.target.value as number]),
          MenuProps: {
            sx: { maxHeight: 240 },
          },
        }}
      >
        {create && (
          <MenuItem key={0} value={0}>
            New subject
          </MenuItem>
        )}
        {subjectsResponse.data?.map(s => (
          <MenuItem key={s.id} value={s.id}>
            {s.name}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
