import { useMemo } from 'react';

const rand = () => {
  return Math.random().toString(36).substring(2);
};

export const useToken = (): string => {
  return useMemo(() => {
    const cookie = localStorage.getItem('mm-kiosk-token') as string;
    if (!cookie) {
      const token = rand() + rand() + rand();
      localStorage.setItem('mm-kiosk-token', token);
      return token;
    }
    return cookie;
  }, []);
};
