import { useSchoolContext } from 'components/context/school-context';
import { CardBase } from 'components/shared/card-base';
import { LoadingSpinner } from 'components/shared/loading-spinner';
import { Body } from './body';
import { useUserMemberTracking } from 'hooks/admin/useMemberTracking';
import { useCustomTheme } from 'hooks/useCustomTheme';

export const MembersChart = () => {
  const { school } = useSchoolContext();
  const { small } = useCustomTheme();
  const memberTracking = useUserMemberTracking(school.id);

  if (!memberTracking.data) {
    return (
      <CardBase sx={{ height: 320, maxWidth: 600, width: small ? '92vw' : '60vw', m: 3 }}>
        <LoadingSpinner />
      </CardBase>
    );
  }

  return <Body memberTracking={memberTracking.data} />;
};
