import { Box, Chip } from '@mui/material';
import { Subject } from 'hooks/useSubject';
import { Location } from 'utils/types';

export const SubjectChip = ({ subject }: { subject: Subject }) => {
  return (
    <Chip
      key={subject.id}
      size="small"
      label={subject.name}
      sx={{
        color: 'white',
        m: 0.5,
        fontWeight: 700,
        stroke: 'red',
        backgroundColor: subject.color,
        height: 21,
      }}
    />
  );
};

export const LocationChip = ({ location }: { location: Location }) => {
  return (
    <Box
      width={'fit-content'}
      key={location.id}
      sx={{
        m: 0.5,
        px: 1,
        color: 'white',
        fontWeight: 700,
        backgroundColor: location.color,
      }}
    >
      {location.description}
    </Box>
  );
};
