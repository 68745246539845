import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useSession } from './useSession';

export type Kiosk = {
  uuid: string;
  token: string;
  schoolId: number;
  userAgent: string;
};

export const useKioskSetup = (school: number) => {
  const { session } = useSession();

  const { data, isLoading } = useQuery<Kiosk, AxiosError>(
    ['kiosk', school],
    async () => {
      const response = await axios.get(`/admin/setup-kiosk`, { headers: { school } });
      return response.data;
    },
    {
      enabled: session,
    }
  );

  return { kiosk: data, isLoading };
};
