import { Card, Grid, TextField, MenuItem, FormControl, Typography, Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSubject } from '../../../hooks/useSubject';
import { useLocation } from '../../../hooks/useLocation';
import { CreateClass } from '../../../utils/types';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { minutesBetween, iso } from '../../../utils/time';
import { DateTime } from 'luxon';
import { useMutate } from 'hooks/useMutate';
import { Box } from '@mui/system';
import { SubjectModal } from 'components/modals/subject-modal';
import { LocationModal } from 'components/modals/location-modal';
import { useSchoolContext } from '../../context/school-context';
import { SubjectSelect } from 'components/shared/subject-select';
import { Session } from 'hooks/useClass';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { CustomModal } from 'components/shared/custom-modal';

enum Repetition {
  SINGLE = 'single',
  LIMITED = 'limited',
  ONGOING = 'ongoing',
}

enum Frequency {
  WEEKLY = '1',
  FORTNIGHTLY = '2',
  THREE_WEEKS = '3',
  FOUR_WEEKS = '4',
  SIX_WEEKS = '6',
  EIGHT_WEEKS = '8',
  TWELVE_WEEKS = '12',
}

type Props = {
  date: string;
  open: boolean;
  close: () => void;
  session?: Session;
};

export const CreateClassModal = ({ date, open, close, session }: Props) => {
  const { school } = useSchoolContext();
  const { create: createSession, destroy: deleteSession } = useMutate<CreateClass>('class', { invalidate: ['class'] });
  const { small } = useCustomTheme();
  const [repetition, setRepetition] = useState<Repetition>(Repetition.SINGLE);

  useEffect(() => {
    if (session && session.endDate === null) {
      setRepetition(Repetition.ONGOING);
    } else {
      if (session?.dateTime !== session?.endDate) setRepetition(Repetition.LIMITED);
    }
  }, [session]);

  const { subjectsResponse } = useSubject(school.id);
  const { locationsResponse } = useLocation(school.id);

  const [subjects, setSubjects] = useState(session?.subject.map(s => s.id) || []);
  const [location, setLocation] = useState(session?.location.id);
  const [dateTime, setDateTime] = useState<DateTime>(iso(session?.dateTime || iso(date).set({ hour: 9 }).toString()));
  const [endDate, setEndDate] = useState<DateTime>(iso(session?.endDate || date));
  const [finishTime, setFinishTime] = useState<DateTime>(
    session ? iso(session.dateTime).plus({ minutes: session.duration }) : iso(date)
  );
  const [frequency, setFrequency] = useState<number | Frequency>(session?.frequency || Frequency.WEEKLY);

  const disabled = useMemo(() => !subjects.length || !location, [subjects, location]);

  const handleCreate = () => {
    const end = repetition === Repetition.SINGLE ? dateTime : endDate;
    const classDto: CreateClass = {
      id: session ? session.id : null,
      schoolId: school.id,
      subjectId: subjects.map(s => Number(s)),
      locationId: Number(location),
      dateTime: dateTime.toUTC().toString(),
      duration: minutesBetween(dateTime, finishTime),
      frequency: Number(frequency),
      week: dateTime.weekNumber % Number(frequency),
      endDate: repetition === Repetition.ONGOING ? null : end.toUTC().toString(),
    };
    createSession(classDto);
    close();
  };

  return subjectsResponse.isSuccess && locationsResponse.isSuccess ? (
    <>
      <SubjectModal
        open={subjects.includes(0)}
        close={i => setSubjects(subjects.map(s => (s === 0 && i ? i : s)).filter(s => s))}
      />
      <LocationModal open={location === 0} close={setLocation} />
      <CustomModal open={open} onClose={close}>
        <Card
          sx={{
            width: small ? '94vw' : '500px',
            m: 'auto',
            p: 6,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography ml={2} variant="h3">
              {session ? 'Edit class' : 'Add class'}
            </Typography>
          </Box>
          <SubjectSelect subjects={subjects} setSubjects={setSubjects} placeholder="subject" multi={false} />
          <FormControl fullWidth>
            <TextField
              select
              label={'location'}
              value={location || ''}
              onChange={i => setLocation(+i.target.value)}
              SelectProps={{
                MenuProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              <MenuItem key={0} value={0}>
                New location
              </MenuItem>
              {locationsResponse.data?.map(l => (
                <MenuItem key={l.id} value={l.id}>
                  {l.description}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <DatePicker
            label={'start date'}
            format={'dd/MM/yyyy'}
            onChange={d => {
              setDateTime(d as DateTime);
              if (d && d.toMillis() > endDate.toMillis()) setEndDate(d);
            }}
            value={dateTime}
          />
          <TextField
            select
            label={'recurrance'}
            value={repetition}
            onChange={i => setRepetition(i.target.value as Repetition)}
          >
            <MenuItem value={Repetition.SINGLE}>{'single event'}</MenuItem>
            <MenuItem value={Repetition.LIMITED}>{'limited'}</MenuItem>
            <MenuItem value={Repetition.ONGOING}>{'ongoing'}</MenuItem>
          </TextField>
          {repetition !== Repetition.SINGLE && (
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
              {repetition === Repetition.LIMITED && (
                <DatePicker
                  sx={{ mr: 4 }}
                  minDate={dateTime}
                  format={'dd/MM/yyyy'}
                  label="end date"
                  onChange={d => d && setEndDate(d)}
                  value={endDate}
                />
              )}
              <TextField
                select
                label={'frequency'}
                value={frequency}
                onChange={i => setFrequency(i.target.value as Frequency)}
              >
                <MenuItem value={Frequency.WEEKLY}>{'weekly'}</MenuItem>
                <MenuItem value={Frequency.FORTNIGHTLY}>{'fortnightly'}</MenuItem>
                <MenuItem value={Frequency.THREE_WEEKS}>{'3 weeks'}</MenuItem>
                <MenuItem value={Frequency.FOUR_WEEKS}>{'4 weeks'}</MenuItem>
                <MenuItem value={Frequency.SIX_WEEKS}>{'6 weeks'}</MenuItem>
                <MenuItem value={Frequency.EIGHT_WEEKS}>{'8 weeks'}</MenuItem>
                <MenuItem value={Frequency.TWELVE_WEEKS}>{'12 weeks'}</MenuItem>
              </TextField>
            </Grid>
          )}
          <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
            <MobileTimePicker
              sx={{ mr: 2 }}
              label="start time"
              onChange={t => {
                setDateTime(t as DateTime);
                if (t && t.toMillis() > finishTime.toMillis()) setFinishTime(t as DateTime);
              }}
              value={dateTime}
            />
            <MobileTimePicker
              sx={{ ml: 2 }}
              label="end time"
              onChange={t => t && setFinishTime(t as DateTime)}
              value={finishTime}
            />
          </Grid>
          <Grid mt={6} item xs={12} display={'flex'} justifyContent={'space-between'}>
            <Box>
              {session && (
                <Button
                  variant="outlined"
                  sx={{ justifySelf: 'start' }}
                  onClick={() => {
                    deleteSession(session.id);
                    close();
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
            <Box>
              <Button
                disabled={disabled}
                variant="contained"
                onClick={() => {
                  handleCreate();
                }}
              >
                {session ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Grid>
        </Card>
      </CustomModal>
    </>
  ) : (
    <></>
  );
};
