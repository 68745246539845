import { Alert, SxProps } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

type Props = {
  severity: 'error' | 'success' | 'info' | 'warning';
  children: JSX.Element;
  height?: number;
  sx?: SxProps;
};

export const Status = ({ severity, children, height = 32, sx }: Props) => {
  const iconStyle = { height: height - 8, mt: -1, marginRight: -1.5 };
  return (
    <Alert
      severity={severity}
      iconMapping={{
        success: <CheckCircleIcon sx={iconStyle} />,
        info: <InfoIcon sx={iconStyle} />,
        warning: <ErrorIcon sx={iconStyle} />,
        error: <ErrorIcon sx={iconStyle} />,
      }}
      variant="outlined"
      sx={{ m: 0, height: height, py: 0, pl: 1, width: 'fit-content', ...sx }}
    >
      {children}
    </Alert>
  );
};
