import { Box, Button, Card, Checkbox, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { CustomModal } from 'components/shared/custom-modal';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { SubscriptionParams } from 'utils/types';

type Props = {
  open: boolean;
  onClose: (arg?: SubscriptionParams) => void;
  type: 'pause' | 'cancel';
};

export const UpdateSubscriptionModal = ({ open, onClose, type }: Props) => {
  const { cardWidth } = useCustomTheme();
  const currentDate = DateTime.now();
  const [dateTime, setDateTime] = useState<DateTime>(currentDate);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(false);
  const text = type === 'pause' ? `Date to resume billing` : `Cancel date`;

  const handleCancel = () => {
    onClose({
      cancel_at_period_end: cancelAtPeriodEnd,
      cancel_at: cancelAtPeriodEnd ? undefined : dateTime.toMillis(),
    });
  };

  const handlePause = () => {
    onClose({ pause_collection: { behavior: 'keep_as_draft', resumes_at: dateTime.toMillis() } });
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <Card sx={{ m: 'auto', width: cardWidth }}>
        <Box width={1} p={4}>
          <Typography variant="h3" mb={6}>
            {text}
          </Typography>
          {type === 'cancel' ? (
            <>
              <Box display="flex" mb={2}>
                <Checkbox checked={cancelAtPeriodEnd} onChange={e => setCancelAtPeriodEnd(e.currentTarget.checked)} />
                <Typography my="auto" mr={2} ml={2}>
                  Cancel at peiod end?
                </Typography>
              </Box>
              <DatePicker
                disabled={cancelAtPeriodEnd}
                disablePast
                label={'Cancel date'}
                format={'dd/MM/yyyy'}
                onChange={d => {
                  if (d && d.toMillis() > currentDate.toMillis()) setDateTime(d as DateTime);
                }}
                value={dateTime}
              />
              <Box textAlign={'end'} mt={6}>
                <Button onClick={handleCancel} variant="contained">
                  {type}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <DatePicker
                disablePast
                label={'Resume date'}
                format={'dd/MM/yyyy'}
                onChange={d => {
                  if (d && d.toMillis() > currentDate.toMillis()) setDateTime(d as DateTime);
                }}
                value={dateTime}
              />
              <Box textAlign={'end'} mt={6}>
                <Button onClick={handlePause} variant="contained">
                  {type}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Card>
    </CustomModal>
  );
};
