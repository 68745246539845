import axios, { AxiosResponse, AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSchoolContext } from '../components/context/school-context';

type Callback<T> = (response: T) => void;
type Options<T> = {
  onSuccess?: Callback<T>;
  headers?: object;
  onError?: (response: string) => void;
  invalidate?: Array<string | number>;
};

export const useMutate = <T>(key: string, options: Options<T> = {}) => {
  const { onSuccess, headers = {}, onError, invalidate } = options;
  const { school } = useSchoolContext();
  const [response, setResponse] = useState<T>();
  const queryClient = useQueryClient();

  const { mutate: create } = useMutation<AxiosResponse<T, any>, AxiosError<any, any>, any>(
    (data: T) => axios.post(`${key}`, data, { headers: { school: school?.id, ...headers } }),
    {
      onSuccess: queryResponse => {
        setResponse(queryResponse.data);
        onSuccess?.(queryResponse.data);
        invalidate && queryClient.invalidateQueries(invalidate);
      },
      onError: queryResponse => {
        onError?.(queryResponse.response?.data['message']);
      },
    }
  );

  const { mutate: update } = useMutation<AxiosResponse<T, any>, AxiosError<any, any>, any>(
    (data: T) => axios.patch(`${key}`, data, { headers: { school: school?.id, ...headers } }),
    {
      onSuccess: queryResponse => {
        setResponse(queryResponse.data);
        onSuccess?.(queryResponse.data);
        invalidate && queryClient.invalidateQueries(invalidate);
      },
      onError: queryResponse => {
        onError?.(queryResponse.response?.data['message']);
      },
    }
  );

  const { mutate: destroy } = useMutation<AxiosResponse<T, any>, AxiosError<any, any>, any>(
    (id: number | string) => axios.delete(`${key}/${id}`, { headers: { school: school?.id } }),
    {
      onSuccess: queryResponse => {
        setResponse(queryResponse.data);
        onSuccess?.(queryResponse.data);
        invalidate && queryClient.invalidateQueries(invalidate);
      },
      onError: queryResponse => {
        onError?.(queryResponse.response?.data['message']);
      },
    }
  );

  return { create, update, destroy, response };
};
