import { Box, Typography } from '@mui/material';
import { CardBase } from 'components/shared/card-base';
import { dateRange, dayName, friendlyDate, iso, isoDate, toIso } from 'utils/time';
import { Carousel } from '../carousel';
import { ClassCard } from './ClassCard';
import { ClassModal } from 'components/home/timetable/class-modal';
import { Closure } from 'hooks/useClosure';
import { DateTime } from 'luxon';
import { useState, useEffect, useMemo, useRef } from 'react';
import { Schedule } from '..';
import { Attendance } from 'hooks/useAttendance';
import { Session } from 'hooks/useClass';
import { ReactComponent as RestImage } from './../../../../assets/rest.svg';

type Props = {
  classes: Session[];
  attendance: Attendance[];
  closures?: Closure[];
};

export const Classes = ({ classes, closures, attendance }: Props) => {
  const start = useRef(toIso(DateTime.now().toISODate()));
  const [now, setNow] = useState(DateTime.now());
  const next = now.plus({ minutes: 30 });
  const later = now.plus({ minutes: 90 });

  const getDecoration = (time: DateTime) => {
    if (time.toISOTime() >= later.toISOTime()) return 'LATER';
    if (time.toISOTime() >= next.toISOTime()) return 'NEXT';
    return 'NOW';
  };

  const [open, setOpen] = useState(false);
  const [currentClass, setCurrentClass] = useState<{ date: string; session: Session }>();
  const date = iso(start.current).toISODate();

  const margin = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(DateTime.now());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const startTime = now.minus({ hours: margin }).toISO();

  const closureRange = (closure: Closure) => {
    if (closure.repeating) {
      return dateRange(isoDate(closure.startDate), isoDate(closure.endDate));
    }
    return dateRange(isoDate(closure.startDate), isoDate(closure.endDate));
  };
  const closureMap = closures?.map(c => ({
    range: closureRange(c),
    data: c,
    locations: c.locations.map(l => l.id),
    subjects: c.subjects.map(s => s.id),
  }));

  const schedule: Schedule = useMemo(
    () => ({
      date,
      sessions: classes
        .filter(
          c =>
            iso(date).weekNumber % c.frequency === c.week &&
            dayName(c.dateTime) === dayName(date) &&
            iso(c.dateTime).hour >= iso(now.toISO()).hour - margin &&
            (c?.endDate ? iso(c.endDate).toMillis() >= iso(startTime).toMillis() : true)
        )
        .map(c => ({
          ...c,
          attendance: attendance
            .filter(a => iso(a.date).toISODate() === date && a.classId === c.id && !a.cancelled)
            .map(i => i.userId),
        })),
    }),
    [classes, date, startTime, now, attendance]
  );

  const groupedSessions = useMemo(
    () =>
      schedule.sessions?.reduce(
        (acc, child, index) => {
          !!index && index % 3 == 0 ? acc.push([child]) : acc[acc.length - 1].push(child);
          return acc;
        },
        [[] as Session[]]
      ),
    [schedule]
  );

  const selectedSession = useMemo(
    () => schedule.sessions.find(i => i.id === currentClass?.session.id),
    [currentClass, schedule]
  );

  const handleClick = (session: Session) => {
    setCurrentClass({ date, session });
  };

  return (
    <>
      {currentClass && selectedSession && (
        <ClassModal
          currentClass={currentClass}
          session={selectedSession}
          attendance={attendance}
          open={open}
          onClose={() => {
            setOpen(false);
            setCurrentClass(undefined);
          }}
        />
      )}
      <CardBase sx={{ height: 'fit-content', m: 3 }}>
        <Typography sx={{ m: 3, mb: 0 }} variant="h3">
          {friendlyDate(date).toUpperCase()}
        </Typography>
        {!!schedule.sessions.length ? (
          <Carousel height={260}>
            {groupedSessions.map((sArray, idx) => (
              <Box key={`g${idx}`} sx={{ scrollSnapAlign: 'center' }}>
                {sArray.map(s => (
                  <ClassCard
                    key={s.id}
                    decoration={getDecoration(iso(s.dateTime))}
                    session={s}
                    handleClick={() => {
                      handleClick(s);
                      setOpen(true);
                    }}
                    closure={
                      closureMap?.find(
                        c =>
                          (!c.locations.length || c.locations.includes(s.location.id)) &&
                          (!c.subjects.length || c.subjects.some(sub => s.subject.some(i => i.id === sub))) &&
                          c.range.some(r => (c.data.repeating ? r.includes(date.substring(5)) : r === date))
                      )?.data?.description
                    }
                  />
                ))}
              </Box>
            ))}
          </Carousel>
        ) : (
          <CardBase sx={{ height: 260 }}>
            <Box display={'flex'} width={1} height={1}>
              <Box m={'auto'}>
                <RestImage height={100} />
              </Box>
            </Box>
          </CardBase>
        )}
      </CardBase>
    </>
  );
};
