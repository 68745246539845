import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { Attendance } from 'hooks/useAttendance';

export const useUserAttendance = (schoolId: number) => {
  const attendanceResponse = useQuery<Attendance[], AxiosError>(['user-attendance'], async () => {
    const response = await axios.get('/admin/attendance', { headers: { school: schoolId } });
    return response.data;
  });

  return attendanceResponse;
};
