import { Box, Button, Card, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import getCroppedImg, { compress, toBlob } from 'utils/image';
import { CustomModal } from 'components/shared/custom-modal';
import Cropper, { Area, Point } from 'react-easy-crop';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { CloseButton } from 'components/shared/close-button';

type Props = {
  logoColor: string;
  onSuccess: (file: File) => void;
  round: boolean;
};

export const ImageUploadModal = ({ logoColor, onSuccess, round }: Props) => {
  const { small } = useCustomTheme();
  const [imageDataUrl, setImageDataUrl] = useState<string>();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixel] = useState<Area>();
  const [croppedImage, setCroppedImage] = useState<Blob | null>(null);

  const handleFileChange = async (fileList: FileList | null) => {
    if (fileList) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageDataUrl(reader.result as string), false);
      reader.readAsDataURL(fileList[0]);
    }
  };

  const showCroppedImage = useCallback(async () => {
    if (imageDataUrl && croppedAreaPixels) {
      try {
        const croppedImg = await getCroppedImg(imageDataUrl, croppedAreaPixels, round);
        setCroppedImage(await toBlob(croppedImg as HTMLCanvasElement));
      } catch (e) {
        console.error(e);
      }
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    if (croppedImage) {
      const f = new File([croppedImage], 'cropped-image', { type: 'image/png' });
      if (!!f) {
        compress(f).then(file => {
          if (file) {
            onSuccess(file);
            setImageDataUrl(undefined);
          }
        });
      }
    }
  }, [croppedImage]);

  return (
    <>
      <IconButton sx={{ my: 'auto' }} size="small" component="label">
        <CloudUploadIcon sx={{ width: 40, height: 40, color: logoColor }} />
        <input type="file" hidden onChange={e => handleFileChange(e.target.files)} />
      </IconButton>
      <CustomModal open={!!imageDataUrl}>
        <Card
          sx={{
            m: 'auto',
          }}
        >
          <Box m={6} display={'flex'} justifyContent={'space-between'}>
            <Typography variant="h3">Select image crop</Typography>
            <CloseButton onClick={() => setImageDataUrl(undefined)} />
          </Box>
          <Box
            m={6}
            boxSizing={'border-box'}
            height={small ? '84vw' : '500px'}
            width={small ? '84vw' : '500px'}
            position={'relative'}
            borderRadius={6}
            overflow={'hidden'}
          >
            <Cropper
              image={imageDataUrl}
              crop={crop}
              setCropSize={() => ({ width: 144, height: 144 })}
              zoom={zoom}
              style={{ cropAreaStyle: { borderRadius: round ? '50%' : 60 } }}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={(area: Area, croppedPix: Area) => {
                setCroppedAreaPixel(croppedPix);
              }}
              onZoomChange={setZoom}
            />
          </Box>
          <Box m={6} display={'flex'} justifyContent={'end'}>
            <Button variant="contained" onClick={showCroppedImage}>
              Save
            </Button>
          </Box>
        </Card>
      </CustomModal>
    </>
  );
};
