import { useMutation, useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useSession } from './useSession';
import { School } from './useSchool';

export type CreateSubject = {
  id?: number;
  name: string;
  schoolId: number;
  description: string;
  color: string;
};

export type Subject = {
  id: number;
  schoolId: number;
  school: School;
  name: string;
  description: string;
  minAge: number;
  maxAge: number;
  color: string;
};

export const useSubject = (schoolId: number) => {
  const { session } = useSession();

  const subjectsResponse = useQuery<Subject[], AxiosError>(
    ['subject', session],
    async () => {
      const response = await axios.get(`/subject?schoolId=${schoolId}`);
      return response.data;
    },
    {
      enabled: session,
    }
  );

  // TODO Refactor to use
  const { mutate: create } = useMutation((id: number) => axios.post('/subject', { membershipId: id }), {});

  const { mutate: destroy } = useMutation((id: number) => axios.delete(`/subject/${id}`), {});

  return { create, destroy, subjectsResponse };
};
