import {
  Modal,
  Card,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  Box,
  Button,
  Checkbox,
} from '@mui/material';
import { SubjectSelect } from 'components/shared/subject-select';
import { useMutate } from 'hooks/useMutate';
import { useMemo, useState } from 'react';
import { CreateMembershipDto, DisplayInterval } from './types';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Membership } from 'hooks/useMemership';
import { Duration, Interval, getDuration } from 'utils/types';
import { iso } from 'utils/time';
import { LocationModal } from '../location-modal';
import { LocationsSelect } from 'components/shared/locations-select';
import { useMembershipStatus } from 'hooks/admin/useMembershipStatus';
import { useSchoolContext } from 'components/context/school-context';
import { Status } from 'components/shared/status';

type Props = {
  open: boolean;
  onClose: () => void;
  membership?: Membership;
};

export const MembershipModal = ({ open, onClose, membership }: Props) => {
  const { small } = useCustomTheme();
  const { school } = useSchoolContext();
  const { create, update, destroy } = useMutate<CreateMembershipDto>('membership', {
    invalidate: ['membership'],
    onSuccess: () => {
      onClose();
    },
  });

  const { data: enrolmentData } = useMembershipStatus(school.id, membership?.id);
  const existingSubscriptions = !!enrolmentData?.enrolments;

  const [subjects, setSubjects] = useState<number[]>(membership?.subjects?.map(m => m.id) || []);
  const [locations, setLocations] = useState(membership?.locations.map(l => l.id) || []);
  const [name, setName] = useState<string>(membership?.name || '');
  const [description, setDescription] = useState<string>(membership?.description || '');
  const [price, setPrice] = useState<number>((membership && membership.price / 100) || 0);
  const [interval, setInterval] = useState<Interval>(membership?.interval || Interval.MONTH);
  const [frequency, setFrequency] = useState<number>(membership?.frequency || 1);
  const [minimumTerm, setMinimumTerm] = useState<number>(membership?.minimumTerm || 1);
  const [duration, setDuration] = useState<Duration>(membership ? getDuration(membership) : Duration.ONGOING);
  const [endDate, setEndDate] = useState<DateTime | null>(membership?.endDate ? iso(membership?.endDate) : null);
  const [limit, setLimit] = useState<number | null>(membership?.limit || null);
  const [attendanceLimit, setAttendanceLimit] = useState<number | undefined>(membership?.attendanceLimit);
  const [updateSubscriptions, setUpdateSubscriptions] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(membership?.hidden || false);
  const [group, setGroup] = useState<boolean>(!!membership?.groupLimit);
  const [groupLimit, setGroupLimit] = useState<number | undefined>(membership?.groupLimit || 1);

  const pricingChange = useMemo(
    () => membership?.price !== price * 100 || membership?.interval !== interval || membership?.frequency !== frequency,
    [membership, price, interval, frequency]
  );

  const disabled = useMemo(
    () =>
      !subjects.length ||
      !locations.length ||
      !name ||
      !interval ||
      !frequency ||
      (duration === Duration.FIXED ? !endDate : false) ||
      (duration === Duration.DYNAMIC ? !limit : false),
    [subjects, locations, name, price, interval, frequency, duration, endDate, limit]
  );

  const handleCreate = () => {
    const membershipDto: CreateMembershipDto = {
      ...(membership && membership),
      type: group ? 'group_membership' : 'membership',
      name,
      description,
      price: price * 100,
      interval: interval || Interval.MONTH,
      frequency: frequency || 1,
      minimumTerm,
      subjects,
      locations,
      attendanceLimit,
      endDate: duration === Duration.FIXED && endDate ? endDate?.toISODate() : null,
      limit: duration === Duration.DYNAMIC ? limit : null,
      updateSubscriptions,
      hidden,
      groupLimit: group ? groupLimit : null,
    };
    membership ? update(membershipDto) : create(membershipDto);
    onClose();
  };

  return (
    <>
      <LocationModal
        open={locations.includes(0)}
        close={i => setLocations(locations.map(l => (l === 0 && i ? i : l)).filter(l => l))}
      />
      <Modal open={open} sx={{ display: 'flex' }} onClose={onClose}>
        <Card
          sx={{
            width: small ? '90vw' : '450px',
            m: 'auto',
            p: 6,
          }}
        >
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography mb={2} variant="h3">
              {membership ? 'Update membership' : 'Create membership'}
            </Typography>
          </Box>
          <TextField label={'membership name'} value={name} onChange={e => setName(e.target.value)} />
          <SubjectSelect subjects={subjects} setSubjects={setSubjects} />
          <LocationsSelect locations={locations} setLocations={setLocations} />
          <TextField label={'description'} value={description} onChange={e => setDescription(e.target.value)} />
          <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
            <TextField
              type="number"
              label={'price'}
              value={price}
              onChange={e => setPrice(Math.round(Number(e.target.value || NaN)))}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              sx={{ mr: 3 }}
            />
            <TextField
              select
              label={'billing interval'}
              value={interval || ''}
              onChange={i => setInterval(i.target.value as Interval)}
              sx={{ ml: 3 }}
            >
              <MenuItem value={Interval.DAY}>{'day'}</MenuItem>
              <MenuItem value={Interval.WEEK}>{'week'}</MenuItem>
              <MenuItem value={Interval.MONTH}>{'month'}</MenuItem>
              <MenuItem value={Interval.YEAR}>{'year'}</MenuItem>
            </TextField>
          </Box>
          <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
            <TextField
              type="number"
              label={`${interval && DisplayInterval[interval]} billing frequency`}
              value={frequency || ''}
              onChange={e => setFrequency(Math.round(Number(e.target.value)))}
              sx={{ mr: 3 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {frequency && interval ? `${frequency} ${interval}${frequency > 1 ? 's' : ''}` : ''}
                  </InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
            />
            <TextField
              type="number"
              label={`cancellation period`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {minimumTerm && interval ? `${minimumTerm} ${interval}${minimumTerm > 1 ? 's' : ''}` : ''}
                  </InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              value={minimumTerm || ''}
              onChange={e => setMinimumTerm(Math.round(Number(e.target.value)))}
              sx={{ ml: 3 }}
            />
          </Box>
          {existingSubscriptions && pricingChange && (
            <Box display={'flex'} my={2} textAlign={'center'}>
              <Checkbox onChange={i => setUpdateSubscriptions(i.currentTarget.checked)} />
              <Typography my={'auto'} ml={2} variant="body1" color="error">
                {'Apply new pricing to existing subscriptions'}
              </Typography>
            </Box>
          )}
          <Box display={'flex'}>
            <TextField
              select
              sx={{ mr: 3 }}
              label={'duration'}
              value={duration}
              onChange={i => setDuration(i.target.value as Duration)}
            >
              <MenuItem value={Duration.ONGOING}>{Duration.ONGOING}</MenuItem>
              <MenuItem value={Duration.FIXED}>{Duration.FIXED}</MenuItem>
              <MenuItem value={Duration.DYNAMIC}>{Duration.DYNAMIC}</MenuItem>
            </TextField>
            <TextField
              type="number"
              sx={{ ml: 3 }}
              label={'attendance limit'}
              value={attendanceLimit || ''}
              onChange={e => setAttendanceLimit(Number(e.target.value))}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">{!!(interval && attendanceLimit) && `per ${interval}`}</InputAdornment>
                ),
              }}
            />
          </Box>
          {duration === Duration.FIXED && (
            <DatePicker label={'end date'} format={'dd/MM/yyyy'} onChange={d => setEndDate(d)} value={endDate} />
          )}
          {duration === Duration.DYNAMIC && (
            <TextField
              type="number"
              label={'limit'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {limit && frequency && interval
                      ? `${limit * frequency} ${interval}${frequency * limit > 1 ? 's' : ''}`
                      : ''}
                  </InputAdornment>
                ),
              }}
              value={limit || ''}
              onChange={e => setLimit(Math.round(Number(e.target.value)))}
            />
          )}
          <Box display={'flex'} my={2}>
            <Box display={'flex'} my={5} textAlign={'center'} width={'50%'} mr={3}>
              <Checkbox checked={group} onChange={i => setGroup(i.currentTarget.checked)} />
              <Typography my={'auto'} ml={2} variant="body1">
                {'Group membership'}
              </Typography>
            </Box>
            {group && (
              <TextField
                type="number"
                sx={{ ml: 3, width: '50%' }}
                label={'group limit'}
                value={groupLimit || ''}
                onChange={e => setGroupLimit(Number(e.target.value))}
                InputProps={{
                  inputProps: { min: 1 },
                }}
              />
            )}
          </Box>
          <Box display={'flex'} my={2} textAlign={'center'}>
            <Checkbox checked={hidden} onChange={i => setHidden(i.currentTarget.checked)} />
            <Typography my={'auto'} ml={2} variant="body1">
              {'Hide from members'}
            </Typography>
          </Box>
          {existingSubscriptions && (
            <Box textAlign={'center'} my={4}>
              <Status severity={'warning'}>
                <Box mt={'-1px'}>{`${enrolmentData?.enrolments} active subscription${
                  enrolmentData?.enrolments > 1 ? 's' : ''
                } to this membership.`}</Box>
              </Status>
            </Box>
          )}
          <Grid mt={4} item xs={12} display={'flex'} justifyContent="space-between">
            <Box>
              {membership && (
                <Button disabled={existingSubscriptions} variant="outlined" onClick={() => destroy(membership.id)}>
                  Delete
                </Button>
              )}
            </Box>
            <Button disabled={disabled} onClick={handleCreate} variant="contained">
              {membership ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Card>
      </Modal>
    </>
  );
};
