import { Box, Button, Grid } from '@mui/material';
import { GoogleAuth } from '../../login/google';
import { User, useUser } from '../../../hooks/useUser';
import { useSession } from 'hooks/useSession';
import { createContext, useContext } from 'react';
import { getAge } from 'utils/time';
import { grey } from '@mui/material/colors';
import { useBrandingContext } from '../branding-context';
import { googleLogout } from '@react-oauth/google';
import { FacebookAuth } from 'components/login/facebook';

export type UserAttributes = {
  user: User;
  users: User[];
  getUserAge: Record<string, number>;
  logout: () => void;
};

const UserContext = createContext<UserAttributes | undefined>(undefined);

export const useUserContext = () => useContext(UserContext) as UserAttributes;

interface Props {
  children: JSX.Element;
}

export const UserContextProvider = ({ children }: Props) => {
  const { logo } = useBrandingContext();
  const { session, setSession, isLoading } = useSession();
  const { user } = useUser(session);

  const logout = () => {
    googleLogout();
    setSession();
  };

  if (!user) {
    return (
      <Box bgcolor={grey[100]} sx={{ height: '100vh', display: 'flex' }}>
        {!session && !isLoading && (
          <>
            <Box sx={{ margin: 'auto' }}>
              <>
                <Box sx={{ textAlign: 'center' }}>
                  <Box width={250} component={'img'} src={logo} borderRadius={'50%'} />
                </Box>
                <Grid container justifyContent="center" py={4}>
                  <Box width={'240px'}>
                    <GoogleAuth setSession={setSession} />
                    <Box m={4} />
                    <FacebookAuth setSession={setSession} />
                  </Box>
                </Grid>
              </>
            </Box>
            <Box position={'absolute'} bottom={0} display={'flex'} width={1} justifyContent={'end'}>
              <Box m={8}>
                <Button
                  variant="text"
                  onClick={() => window.location.assign('https://martial-manager.com/privacy-policy/')}
                >
                  Privacy policy
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  }

  return (
    <UserContext.Provider
      value={{
        user,
        users: [user, ...user.children],
        getUserAge: Object.fromEntries([user, ...user.children].map(i => [i.id, getAge(i.birthday)])),
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
