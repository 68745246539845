import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { KioskContextProvider } from 'components/context/kiosk-context';
import { Kiosk } from 'components/kiosk';

export const KioskApp = () => (
  <KioskContextProvider>
    <Box height={'100vh'} sx={{ background: grey[100] }}>
      <Kiosk />
    </Box>
  </KioskContextProvider>
);
