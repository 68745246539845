import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { CardBase } from 'components/shared/card-base';
import { useCustomTheme } from 'hooks/useCustomTheme';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { AddButton } from 'components/shared/add-button';
import { ClosureModal } from 'components/modals/closure-modal';
import { Closure, useClosure } from 'hooks/useClosure';
import { iso, isoDate } from 'utils/time';
import { LocationChip, SubjectChip } from 'components/shared/chip';
import { AuthWrapper } from 'components/auth-wrapper';
import { DateTime } from 'luxon';

export const Closures = () => {
  const { cardWidth } = useCustomTheme();
  const { school, member } = useSchoolContext();
  const { data } = useClosure(school.id);
  const [closure, setClosure] = useState<Closure>();
  const [open, setOpen] = useState<boolean>(false);

  const filteredData = member.isAdmin ? data : data?.filter(c => c.repeating || iso(c.startDate) > DateTime.now());

  const formatDate = (date: string, recurring: boolean) => {
    if (recurring) {
      return `${iso(date).toLocaleString({
        month: 'long',
        day: '2-digit',
      })} annually`;
    }
    return iso(date).toLocaleString({
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    });
  };

  return (
    <>
      <ClosureModal
        key={closure?.id}
        closure={closure}
        open={!!closure || open}
        close={() => {
          setClosure(undefined);
          setOpen(false);
        }}
      />
      <Grid container justifyContent={'center'} height={'94.5vh'} overflow={'scroll'} mt={'4vh'}>
        <Grid container maxWidth={1200} justifyContent={'center'} alignContent={'center'} mb={30}>
          {filteredData
            ?.sort((a, b) => iso(a.startDate).ordinal - iso(b.startDate).ordinal)
            ?.map(c => (
              <CardBase key={c.id} sx={{ height: 'fit-content', my: 4, m: 3, width: cardWidth }}>
                <Box display={'flex'} width={1} justifyContent={'space-between'}>
                  <Box width={1} ml={4} my={4} alignItems={'space-between'}>
                    <Box width={1} display={'flex'} justifyContent={'space-between'}>
                      <Typography variant="h3" mb={1}>
                        {c.description}
                      </Typography>
                      <Box mt={-3} mr={1}>
                        <AuthWrapper>
                          <IconButton size="small" onClick={() => setClosure(c)}>
                            <EditIcon sx={{ height: 18, width: 18 }} />
                          </IconButton>
                        </AuthWrapper>
                      </Box>
                    </Box>
                    <Typography variant="h5" mb={1}>
                      {`${formatDate(c.startDate, c.repeating)}${
                        isoDate(c.startDate) === isoDate(c.endDate) ? '' : ` - ${formatDate(c.endDate, c.repeating)}`
                      }`}
                    </Typography>
                    {!c.subjects.length ? (
                      <Chip
                        size="small"
                        label={'All classes'}
                        sx={{
                          color: 'white',
                          m: 0.5,
                          fontWeight: 700,
                          stroke: 'red',
                          backgroundColor: school.priColor,
                          height: 21,
                        }}
                      />
                    ) : (
                      c.subjects.map(s => <SubjectChip key={s.id} subject={s} />)
                    )}
                    <Box flexWrap={'wrap'} display={'flex'}>
                      {!c.locations.length ? (
                        <Box
                          width={'fit-content'}
                          sx={{
                            m: 0.5,
                            px: 1,
                            color: 'white',
                            fontWeight: 700,
                            backgroundColor: school.priColor,
                          }}
                        >
                          All locations
                        </Box>
                      ) : (
                        c.locations.map(l => <LocationChip key={l.id} location={l} />)
                      )}
                    </Box>
                  </Box>
                </Box>
              </CardBase>
            ))}
          <AuthWrapper>
            <AddButton label="Add closure" onClick={() => setOpen(true)} sx={{ m: 3, width: cardWidth }} />
          </AuthWrapper>
        </Grid>
      </Grid>
    </>
  );
};
