import { createContext, useContext, useMemo } from 'react';
import { THEME, createCustomTheme } from 'Theme';
import { Box, ThemeProvider, Typography } from '@mui/material';
import { useBranding } from 'hooks/useBranding';
import logo from '../../../assets/mm-logo.svg';
import { useParams } from 'react-router-dom';

type BrandingContextAttributes = {
  logo: string;
  priColor: string;
  secColor: string;
};

const BrandingContext = createContext<BrandingContextAttributes>({
  logo,
  priColor: THEME.palette.primary.main,
  secColor: THEME.palette.secondary.main,
});

export const useBrandingContext = () => useContext(BrandingContext) as BrandingContextAttributes;

interface Props {
  children: JSX.Element;
}

export const BrandingContextProvider = ({ children }: Props) => {
  const { uuid } = useParams();
  const { data: branding, isLoading } = useBranding(uuid);
  const value = useMemo(
    () => ({
      priColor: THEME.palette.primary.main,
      secColor: THEME.palette.secondary.main,
      logo,
      ...branding,
    }),
    [branding, uuid]
  );

  if (isLoading) {
    return <></>;
  }

  if (uuid && !branding) {
    return (
      <Box display={'flex'}>
        <Typography m={'auto'} variant="h2">
          Error loading club
        </Typography>
      </Box>
    );
  }

  const customTheme = branding ? createCustomTheme(branding.priColor, branding.secColor, branding.terColor) : THEME;

  return (
    <ThemeProvider theme={customTheme}>
      <BrandingContext.Provider value={value}>{children}</BrandingContext.Provider>
    </ThemeProvider>
  );
};
