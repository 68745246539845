import { Box, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { Session } from 'hooks/useClass';

import SearchIcon from '@mui/icons-material/Search';
import { useMemo, useState } from 'react';
import { useKioskContext } from 'components/context/kiosk-context';
import { KioskUser, useKioskUsers } from 'hooks/useKioskUsers';
import { friendlyDate, friendlyTime, iso } from 'utils/time';
import { DateTime } from 'luxon';
import { useKioskAttendance } from 'hooks/useKioskAttendance';
import { BookingResponse, ResponseType } from './booking-response';
import ClearIcon from '@mui/icons-material/Clear';
import { DisplayImage } from 'components/shared/display-image';
import success from '../../../assets/success.mp3';
import error from '../../../assets/error.mp3';
import { CheckInButton } from 'components/shared/checkin-button';

type Props = {
  session: Session;
  onChange: () => void;
};

export const Search = ({ session, onChange }: Props) => {
  const { kiosk, setSession } = useKioskContext();
  const { users } = useKioskUsers(kiosk);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<KioskUser>();
  const [responseType, setResponseType] = useState<ResponseType>();

  const handleResponse = (type: ResponseType) => {
    new Audio(type === 'success' ? success : error).play();
    setResponseType(type);
    setTimeout(() => {
      setSession(undefined);
    }, 4000);
  };

  const { create: book } = useKioskAttendance(
    kiosk,
    () => handleResponse('success'),
    () => handleResponse('error')
  );

  const handleBook = (user: KioskUser) => {
    book({ userId: user.id, classId: session.id, date: DateTime.now().toISODate() });
  };

  const filteredUsers = useMemo(
    () =>
      users?.filter(i =>
        [`${i.firstName} ${i.lastName}`, i.lastName, i.email].some(
          v => searchTerm && !!v?.toLowerCase().match(new RegExp(`(^${searchTerm.replace(/[^a-zA-Z0-9 ]/g, '')})`, 'g'))
        )
      ) || [],
    [searchTerm]
  );

  const handleSelectUser = (user: KioskUser) => {
    setSelectedUser(user);
    setSearchTerm('');
  };

  return (
    <>
      <BookingResponse type={responseType} name={selectedUser?.firstName} />
      <Box display={'flex'} justifyContent={'center'}>
        <Box width={300}>
          <Box display={'flex'} justifyContent={'center'}>
            <Box>
              <Box display="flex" my={6}>
                <Box width={45}>
                  <Box
                    sx={{
                      height: 35,
                      width: 35,
                      borderRadius: 50,
                      backgroundColor: session.subject[0].color,
                      mr: 2,
                    }}
                  />
                </Box>
                <Typography variant="h1" textAlign={'left'}>
                  {session.subject
                    .map(i => i.name)
                    .join(', ')
                    .toString()}
                </Typography>
              </Box>
              <Typography variant="h1" fontWeight={300}>
                {friendlyDate(DateTime.now().toISODate()).toUpperCase()}
              </Typography>
              <Typography variant="h1" fontWeight={300}>
                {`${friendlyTime(session.dateTime)} - ${friendlyTime(
                  iso(session.dateTime).plus({ minutes: session.duration }).toISO()
                )}`}
              </Typography>
              <Typography variant="h2" color={session.location.color}>
                {session.location.description}
              </Typography>
            </Box>
          </Box>
          <TextField
            autoComplete="off"
            value={searchTerm}
            onChange={({ currentTarget }) => {
              setSearchTerm(currentTarget.value.toLowerCase());
              setSelectedUser(undefined);
              onChange();
            }}
            sx={{ maxWidth: 300 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton sx={{ mr: -4 }}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <>
            {filteredUsers?.map(i => (
              <MenuItem key={i.firstName} sx={{ borderRadius: 50, pl: 2 }} onClick={() => handleSelectUser(i)}>
                <DisplayImage firstName={i.firstName} picture={i.picture} size={30} sx={{ mr: 2 }} />
                <Typography overflow={'hidden'} textOverflow={'ellipsis'}>{`${i.firstName} ${i.lastName}`}</Typography>
              </MenuItem>
            ))}
          </>
          {selectedUser && (
            <Box display="flex" justifyContent={'center'}>
              <Box textAlign={'center'}>
                <DisplayImage
                  firstName={selectedUser.firstName}
                  picture={selectedUser.picture}
                  size={70}
                  sx={{ m: 'auto', my: 4 }}
                />
                <Typography variant="h2" fontWeight={400}>
                  {`${selectedUser.firstName} ${selectedUser.lastName}`}
                </Typography>
                <Box my={4} display={'flex'} justifyContent={'center'}>
                  {selectedUser && (
                    <CheckInButton name={selectedUser.firstName} onClick={() => handleBook(selectedUser)} />
                  )}
                </Box>
                <IconButton onClick={() => setSelectedUser(undefined)}>
                  <ClearIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
