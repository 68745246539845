import FacebookLogin from '@greatsumini/react-facebook-login';
import { Button } from '@mui/material';
import axios from 'axios';

type Props = { setSession: (jwt: string) => void };

export const FacebookAuth = ({ setSession }: Props) => {
  const handleLogin = async (accessToken: any) => {
    const { token } = await axios.post('/login/facebook', { token: accessToken }).then(response => response.data);
    setSession(token);
  };

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
      onSuccess={response => {
        handleLogin(response.accessToken);
      }}
      onFail={error => {
        console.log('Login Failed!', error);
      }}
      render={({ onClick }) => (
        <Button
          variant="contained"
          type="button"
          sx={{
            height: 38,
            backgroundColor: '#4267b2',
            color: 'white',
            width: '100%',
            borderRadius: 50,
            ':hover': { bgcolor: '#5978b7' },
          }}
          onClick={onClick}
        >
          Sign in with Facebook
        </Button>
      )}
    />
  );
};
