import { IconButton, SxProps } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSchoolContext } from 'components/context/school-context';
import { useMutate } from 'hooks/useMutate';

type Props = { onSuccess?: () => void; sx?: SxProps };

export const FileUploadButton = ({ onSuccess, sx }: Props) => {
  const { school } = useSchoolContext();
  const { create } = useMutate('/school/upload-terms', {
    headers: { uri: school.termsAndConditions },
    onSuccess: onSuccess,
  });

  const handleFileChange = async (fileList: FileList | null) => {
    if (fileList) {
      const formData = new FormData();
      formData.append('file', fileList[0]);
      create(formData);
    }
  };

  return (
    <IconButton sx={{ my: 'auto', ...sx }} size="small" component="label">
      <CloudUploadIcon sx={{ width: 40, height: 40, color: school.priColor }} />
      <input type="file" hidden onChange={e => handleFileChange(e.target.files)} />
    </IconButton>
  );
};
