import { Grid, Card, Typography, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { Membership } from 'hooks/useMemership';
import { displayInterval } from 'utils/types';
import { grey } from '@mui/material/colors';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { endDate } from 'utils/time';
import { AuthWrapper } from 'components/auth-wrapper';
import EditIcon from '@mui/icons-material/Edit';
import { MembershipModal } from 'components/modals/membership-modal';
import { useState } from 'react';
import { ClassPassModal } from 'components/modals/class-pass-modal';
import { LocationChip, SubjectChip } from 'components/shared/chip';

type MembershipProps = {
  membership: Membership;
  children?: React.ReactNode;
};

export const MembershipType: Record<string, string> = {
  membership: 'Membership',
  class_pass: 'Class Pass',
  group_membership: 'Group Membership',
};

export const MembershipCard: React.FC<MembershipProps> = ({ membership, children }) => {
  const { small } = useCustomTheme();
  const [modal, setModal] = useState(false);

  const getAttendanceText = (): string => {
    if (membership.type.includes('membership')) {
      return membership.attendanceLimit
        ? ` ${membership.attendanceLimit} class ${displayInterval(membership.interval, membership.frequency)}`
        : ' Unlimited';
    }
    return ` ${membership.attendanceLimit} classes`;
  };

  return (
    <>
      {modal && membership.type.includes('membership') && (
        <MembershipModal membership={membership} open={modal} onClose={() => setModal(false)} />
      )}
      {modal && membership.type === 'class_pass' && (
        <ClassPassModal membership={membership} open={modal} onClose={() => setModal(false)} />
      )}
      <Grid style={{ padding: '12px' }} sx={{ ...(small && { scrollSnapAlign: 'center', scrollSnapStop: 'always' }) }}>
        <Card style={{ height: '450px', width: '350px', padding: 16 }}>
          <Grid container height={1} direction={'column'} justifyContent={'space-between'}>
            <Grid container flexGrow={1} direction={'column'} justifyContent={'space-between'}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: `solid 1px ${grey[300]}` }}>
                <Box sx={{ display: 'flex', pb: 1 }} justifyContent={'space-between'}>
                  <Box>
                    <Typography variant="h3">{membership.name}</Typography>
                    <Box display={'flex'}>
                      {membership.hidden && (
                        <Typography color={'error'} variant="h3">
                          Hidden &nbsp;
                        </Typography>
                      )}
                      <Typography key={membership.type} variant="h4">
                        {MembershipType[membership.type]}
                      </Typography>
                    </Box>
                    <Typography variant="h5">
                      Attendance:
                      {getAttendanceText()}
                    </Typography>
                    <Typography variant="h5">
                      {`$${(membership.price / 100).toFixed(2)} ${displayInterval(
                        membership.interval,
                        membership.frequency
                      )}`}
                    </Typography>
                    {(membership.endDate || membership.limit) && (
                      <Typography variant="h5">{endDate(membership)}</Typography>
                    )}
                  </Box>
                </Box>
                <AuthWrapper>
                  <Box mr={-1.5} mt={-1.5}>
                    <IconButton size="small" onClick={() => setModal(true)}>
                      <EditIcon sx={{ height: 18, width: 18 }} />
                    </IconButton>
                  </Box>
                </AuthWrapper>
              </Box>
              <Grid container flexGrow={1} direction={'column'} overflow={'scroll'}>
                <Box maxHeight={2}>
                  <Box pt={1}>
                    <Typography variant="h5">Subjects</Typography>
                  </Box>
                  <Box>
                    {membership.subjects.map(i => (
                      <SubjectChip key={i.id} subject={i} />
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Box>
                <Box pt={1} sx={{ borderTop: `solid 1px ${grey[300]}` }}>
                  <Typography variant="h5">Locations</Typography>
                </Box>
                <Grid container mb={1}>
                  {membership.locations.map(i => (
                    <LocationChip key={i.id} location={i} />
                  ))}
                </Grid>
              </Box>
            </Grid>
            <>{children}</>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};
