import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export type Branding = {
  logo: string;
  priColor: string;
  secColor: string;
  terColor: string;
};

export const useBranding = (id?: string) => {
  const logoResponse = useQuery<Branding, AxiosError>(
    ['logo', id],
    async () => {
      const response = await axios.get(`/branding/${id}`);
      return response.data;
    },
    {
      enabled: !!id,
    }
  );

  return logoResponse;
};
