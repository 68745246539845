import { Interval } from 'utils/types';

export type CreateMembershipDto = {
  name: string;
  type: string;
  description: string;
  price: number;
  subjects: number[];
  locations: number[];
  interval: Interval;
  frequency: number;
  minimumTerm: number;
  endDate: string | null;
  limit: number | null;
  hidden: boolean;
  attendanceLimit?: number;
  updateSubscriptions?: boolean;
  groupLimit?: number | null;
};

export enum DisplayInterval {
  day = 'daily',
  week = 'weekly',
  month = 'monthly',
  year = 'yearly',
}
