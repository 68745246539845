import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import { DateTime } from 'luxon';
import { Session, useClass } from '../../../hooks/useClass';
import { dateRange, dayName, dayTime, friendlyDate, iso, isoDate, toIso } from 'utils/time';
import { useAttendance } from 'hooks/useAttendance';
import { CreateClassModal } from 'components/modals/create-class-modal';
import { ClassCard } from './ClassCard';
import { AuthWrapper } from 'components/auth-wrapper';
import { useSchoolContext } from '../../context/school-context';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { ClassModal } from './class-modal';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AddButton } from 'components/shared/add-button';
import { Closure, useClosure } from 'hooks/useClosure';

export type Schedule = {
  date: string;
  sessions: Session[];
};

export const Timetable = () => {
  const { school } = useSchoolContext();
  const ref = useRef<HTMLElement>(null);
  const scrollRef = useRef(0);
  const { onMouseDown } = useDraggableScroll(ref, { direction: 'both' });

  const start = useRef(toIso(DateTime.now().toISODate()));
  const end = useRef(toIso(DateTime.now().plus({ days: 14 }).toISODate()));

  const { classes } = useClass(school.id, start.current, end.current);
  const { attendance } = useAttendance(school.id, start.current);
  const { data: closures } = useClosure(school.id);

  const [open, setOpen] = useState(false);
  const [currentClass, setCurrentClass] = useState<{ date: string; session: Session }>();
  const { small } = useCustomTheme();
  const interval = dateRange(start.current, end.current);

  const [createModal, setCreateModal] = useState<{ date: string; session?: Session }>();

  const closureRange = (closure: Closure) => {
    if (closure.repeating) {
      return dateRange(isoDate(closure.startDate), isoDate(closure.endDate));
    }
    return dateRange(isoDate(closure.startDate), isoDate(closure.endDate));
  };
  const closureMap = closures?.map(c => ({
    range: closureRange(c),
    data: c,
    locations: c.locations.map(l => l.id),
    subjects: c.subjects.map(s => s.id),
  }));

  const schedule: Schedule[] = useMemo(
    () =>
      interval.map(d => ({
        date: d,
        sessions: classes
          .filter(c => dayName(c.dateTime) === dayName(d))
          .filter(c =>
            c?.endDate ? iso(new Date(c.endDate).toISOString()).toMillis() >= iso(d).toMillis() : !c.endDate
          )
          .filter(c => iso(d).weekNumber % c.frequency === c.week)
          .map(c => ({
            ...c,
            attendance: attendance
              .filter(a => iso(a.date).toISODate() === d && a.classId === c.id && !a.cancelled)
              .map(i => i.userId),
          }))
          .sort((a, b) => dayTime(a.dateTime) - dayTime(b.dateTime)),
      })),
    [classes, interval, attendance]
  );

  const selectedSession = useMemo(
    () => schedule.find(s => s.date === currentClass?.date)?.sessions.find(i => i.id === currentClass?.session.id),
    [currentClass, schedule]
  );

  const handleScroll = (scroll: number) => {
    if (ref?.current) ref.current.scrollTo({ left: scrollRef.current + scroll });
  };

  const handleClick = (date: string, session: Session) => {
    setCurrentClass({ date, session });
  };

  return (
    <>
      {!!createModal && (
        <CreateClassModal
          date={createModal.date}
          open={!!createModal}
          close={() => {
            setCreateModal(undefined);
            setCurrentClass(undefined);
          }}
          session={createModal?.session}
        />
      )}
      {currentClass && selectedSession && (
        <ClassModal
          currentClass={currentClass}
          session={selectedSession}
          attendance={attendance}
          open={open}
          onClose={() => {
            setOpen(false);
            setCurrentClass(undefined);
          }}
        />
      )}
      <Grid container>
        <Grid container justifyContent="center">
          <Box
            onScroll={e => (scrollRef.current = e.currentTarget.scrollLeft)}
            overflow={'auto'}
            display={'flex'}
            sx={{ ...(small && { scrollSnapType: 'x mandatory', scrollBehavior: 'smooth' }) }}
            ref={ref}
            pr={50}
            onMouseDown={onMouseDown}
          >
            {schedule.map(day => (
              <Box key={day.date} sx={{ px: 3, scrollSnapAlign: 'center', scrollSnapStop: 'always' }} mt={'8vh'}>
                <Box sx={{ width: small ? '94vw' : '350px', overflow: 'hidden' }}>
                  <Box sx={{ display: 'flex', width: '100%', borderBottom: 2, borderColor: 'primary.main' }}>
                    <Box sx={{ display: 'flex', width: 'inherit', justifyContent: 'space-evenly' }}>
                      {small && (
                        <IconButton onClick={() => handleScroll(-screen.width)}>
                          <ChevronLeftIcon />
                        </IconButton>
                      )}
                      <Typography variant="h2" py={2} sx={{ color: school.priColor }}>
                        {friendlyDate(day.date)}
                      </Typography>
                      {small && (
                        <IconButton onClick={() => handleScroll(screen.width)}>
                          <ChevronRightIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      overflow: 'scroll',
                      height: '80vh',
                      pb: 40,
                    }}
                  >
                    {day.sessions.map(s => (
                      <ClassCard
                        key={s.id}
                        classes={s}
                        handleClick={() => {
                          handleClick(day.date, s);
                          setOpen(true);
                        }}
                        closure={
                          closureMap?.find(
                            c =>
                              (!c.locations.length || c.locations.includes(s.location.id)) &&
                              (!c.subjects.length || c.subjects.some(sub => s.subject.some(i => i.id === sub))) &&
                              c.range.some(r => (c.data.repeating ? r.includes(day.date.substring(5)) : r === day.date))
                          )?.data?.description
                        }
                      />
                    ))}
                    <AuthWrapper>
                      <AddButton
                        label="Add class"
                        onClick={() => {
                          setCreateModal({ date: day.date });
                        }}
                        sx={{ mx: 1, mt: 6 }}
                      />
                    </AuthWrapper>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
