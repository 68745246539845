import { Card, Box, Typography, ButtonBase, Button, IconButton } from '@mui/material';
import { AuthWrapper } from 'components/auth-wrapper';
import { useSchoolContext } from 'components/context/school-context';
import { useUserContext } from 'components/context/user-context';
import { CreateClassModal } from 'components/modals/create-class-modal';
import { CustomModal } from 'components/shared/custom-modal';
import { DisplayImage } from 'components/shared/display-image';
import { Session } from 'hooks/useClass';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { useMutate } from 'hooks/useMutate';
import { useQueryState } from 'hooks/useQueryState';
import { groupBy } from 'lodash';
import { useMemo, useState } from 'react';
import { byValidAge } from 'utils/eligibility';
import { validateAttendanceWindow, friendlyTime, iso } from 'utils/time';
import { ActiveStatus, PageType, Tabs } from 'utils/types';
import EditIcon from '@mui/icons-material/Edit';
import success from '../../../assets/success.mp3';
import { Attendance } from 'hooks/useAttendance';
import { grey } from '@mui/material/colors';
import { CheckInButton } from '../../shared/checkin-button';
import { TermsAndConditionsModal } from 'components/modals/terms-and-conditions-modal';

type Props = {
  session: Session;
  currentClass: { date: string; session?: Session };
  attendance: Attendance[];
  open: boolean;
  onClose: () => void;
};

export const ClassModal = ({ open, onClose, session, attendance, currentClass }: Props) => {
  const [, setQuery] = useQueryState();
  const { cardWidth } = useCustomTheme();
  const { users } = useUserContext();
  const { school, member } = useSchoolContext();
  const [unsignedUserId, setUnsignedUserId] = useState<number>();

  console.log('...member.children]', [member, ...member.children]);

  const userEnrolments = useMemo(
    () =>
      groupBy(
        [member, ...member.children]
          .map(m =>
            [m.enrolments.filter(e => e.membership.type != 'group_membership'), m.groupEnrolments]
              .flat()
              .filter(e => ActiveStatus.includes(e.status))
              .map(e => ({
                key: m.userId,
                ...e,
              }))
          )
          .flat(),
        'key'
      ),
    [member]
  );

  const userAttendance = groupBy(attendance, i => i.userId);
  const eligibleUsers = useMemo(() => byValidAge(users, session.subject), [session]);
  const classTime = useMemo(() => `${currentClass.date}T${iso(session.dateTime).toISOTime()}`, [currentClass, session]);

  const enrolledUsers = useMemo(
    () =>
      eligibleUsers.filter(u =>
        userEnrolments[u.id]?.some(
          e =>
            e.membership.locations.some(l => l.id === session.location.id) &&
            e.membership.subjects.some(s => session.subject.map(x => x.id).includes(s.id)) &&
            (!e.membership.type.includes('membership') ||
              (e.membership.attendanceLimit
                ? validateAttendanceWindow(currentClass.date, userAttendance[u.id], e.membership)
                : true))
        )
      ),
    [session]
  );

  const [createModal, setCreateModal] = useState(false);
  const { create: book } = useMutate<Attendance>('attendance', {
    invalidate: ['attendance'],
    onSuccess: (x: Attendance) => {
      if (!x.cancelled) new Audio(success).play();
    },
  });

  const handleBook = (userId: number) => {
    if (member.signedTerms || !school.termsAndConditions) {
      book({ userId, classId: session.id, date: classTime });
    } else {
      setUnsignedUserId(userId);
    }
  };

  return (
    <>
      <CreateClassModal
        date={currentClass.date}
        open={createModal}
        close={() => setCreateModal(false)}
        session={session}
      />
      <TermsAndConditionsModal
        userId={unsignedUserId && member.userId}
        onClose={signed => {
          if (signed) {
            book({ userId: unsignedUserId, classId: session.id, date: classTime });
          }
          setUnsignedUserId(undefined);
        }}
      />
      <CustomModal open={open} onClose={onClose}>
        <Card sx={{ width: cardWidth, m: 'auto', p: 6 }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box>
                {session.subject.map(s => (
                  <Typography variant="h2" textAlign={'left'} key={s.id}>
                    {s.name}
                  </Typography>
                ))}
              </Box>
            </Box>
            <AuthWrapper>
              <Box mr={-5} mt={-5}>
                <IconButton size="small" onClick={() => setCreateModal(true)}>
                  <EditIcon sx={{ height: 18, width: 18 }} />
                </IconButton>
              </Box>
            </AuthWrapper>
          </Box>
          <Typography variant="h4" sx={{ mt: 1 }}>
            {`${friendlyTime(session.dateTime)} - ${friendlyTime(
              iso(session.dateTime).plus({ minutes: session.duration }).toISO()
            )}`}
          </Typography>
          <Typography variant="h3" color={session.location.color}>
            {session.location.description}
          </Typography>
          <Typography variant="h5">{session.location.address}</Typography>
          {session.subject.map(s => (
            <Typography key={s.id} variant="h5" my={3}>
              {s.description}
            </Typography>
          ))}
          {eligibleUsers.length === 0 ? (
            <Box borderTop={`solid 1px ${grey[300]}`} textAlign={'center'}>
              <Typography variant="h4" mt={5}>
                You are not eligible for this class
              </Typography>
            </Box>
          ) : (
            <Box mt={3} textAlign={'center'}>
              {enrolledUsers.length ? (
                <Box borderTop={`solid 1px ${grey[300]}`}>
                  {enrolledUsers.length === 1 ? (
                    <CheckInButton
                      name={enrolledUsers[0].firstName}
                      active={session.attendance.includes(enrolledUsers[0]?.id)}
                      onClick={() => handleBook(enrolledUsers[0]?.id)}
                    />
                  ) : (
                    <>
                      <Typography variant="h5" mt={2}>
                        Select attendees
                      </Typography>
                      <Box mt={4} display={'flex'} justifyContent={'center'}>
                        {enrolledUsers.map(i => (
                          <ButtonBase key={i.id} onClick={() => handleBook(i.id)} sx={{ mx: 2, borderRadius: 50 }}>
                            <DisplayImage
                              firstName={i.firstName}
                              picture={i.picture}
                              size={50}
                              selected={session.attendance.includes(i.id)}
                            />
                          </ButtonBase>
                        ))}
                      </Box>
                    </>
                  )}
                </Box>
              ) : (
                <>
                  <Button
                    variant="text"
                    onClick={() => {
                      setQuery({
                        page: PageType.user_settings,
                        [`${PageType.user_settings} tab`]: Tabs[PageType.user_settings][2],
                      });
                      onClose();
                    }}
                  >
                    {'Sign up now to access this class'}
                  </Button>
                </>
              )}
            </Box>
          )}
        </Card>
      </CustomModal>
    </>
  );
};
