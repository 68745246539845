import { Box } from '@mui/material';
import { CustomModal } from 'components/shared/custom-modal';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { HexColorPicker } from 'react-colorful';

type Props = {
  open: boolean;
  onClose: () => void;
  color: string;
  setColor: (arg: string) => void;
};

export const ColorPicker = ({ open, onClose, color, setColor }: Props) => {
  const { cardWidth } = useCustomTheme();

  return (
    <CustomModal open={open} onClose={onClose}>
      <Box m={'auto'} width={cardWidth} height={cardWidth}>
        <HexColorPicker
          style={{
            zIndex: 99,
            position: 'absolute',
            height: cardWidth,
            width: cardWidth,
          }}
          color={color}
          onChange={setColor}
        />
      </Box>
    </CustomModal>
  );
};
