import { Alert, Snackbar } from '@mui/material';

export const Toast = ({ message, open, onClose }: { message: string; open: boolean; onClose: () => void }) => (
  <Snackbar
    open={open}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    autoHideDuration={3000}
    onClose={onClose}
  >
    <Alert severity="success" variant="outlined" sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
);
