import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { CardBase } from 'components/shared/card-base';
import { useCustomTheme } from 'hooks/useCustomTheme';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { Subject, useSubject } from 'hooks/useSubject';
import { SubjectModal } from 'components/modals/subject-modal';
import { AddButton } from 'components/shared/add-button';

export const Subjects = () => {
  const { cardWidth } = useCustomTheme();
  const { school } = useSchoolContext();
  const { subjectsResponse } = useSubject(school.id);
  const [subject, setSubject] = useState<Subject>();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <SubjectModal
        key={subject?.id}
        subject={subject}
        open={!!subject || open}
        close={() => {
          setSubject(undefined);
          setOpen(false);
        }}
      />
      <Grid container my={6} pt={4} mb={24} maxWidth={1200} justifyContent={'center'} alignContent={'center'}>
        {subjectsResponse.data
          ?.sort((a, b) => a.id - b.id)
          ?.map(i => (
            <CardBase key={i.id} sx={{ display: 'flex', height: 70, my: 4, m: 3, width: cardWidth }}>
              <Box width={18} bgcolor={i.color} />
              <Box width={1} display={'flex'} justifyContent={'space-between'}>
                <Box ml={2} my={'auto'} alignItems={'space-between'}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h3" mb={1}>
                      {i.name}
                    </Typography>
                  </Box>
                </Box>
                <Box mr={1} mt={1}>
                  <IconButton size="small" onClick={() => setSubject(i)}>
                    <EditIcon sx={{ height: 18, width: 18 }} />
                  </IconButton>
                </Box>
              </Box>
            </CardBase>
          ))}
        <AddButton label="Add subject" onClick={() => setOpen(true)} sx={{ m: 3, width: cardWidth }} />
      </Grid>
    </>
  );
};
