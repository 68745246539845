import React from 'react';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';

interface Props {
  setSession: React.Dispatch<string>;
}

export const GoogleAuth = ({ setSession }: Props) => {
  const handleLogin = async (googleData: any) => {
    const { token } = await axios.post('/login/google', googleData).then(response => response.data);
    setSession(token);
  };
  return (
    <GoogleLogin
      shape="pill"
      onSuccess={handleLogin}
      onError={() => {
        console.log('Login Failed');
      }}
      useOneTap
      auto_select
      state_cookie_domain="http://localhost:3000/"
      width="240px"
    />
  );
};
