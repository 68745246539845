import { Box, Button, InputAdornment, MenuItem, TextField } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { ModalBase } from 'components/shared/modal-base';
import { Coupon, useCoupons } from 'hooks/useCoupons';
import { Enrolment } from 'hooks/useMember';
import { useMutate } from 'hooks/useMutate';
import { useState } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  enrolment: Enrolment;
};

export const EnrolmentPriceModal = ({ open, onClose, enrolment }: Props) => {
  const { school } = useSchoolContext();
  const [coupon, setCoupon] = useState<Coupon>();
  const [price, setPrice] = useState<number>(enrolment.price / 100);
  const couponsReponse = useCoupons(school.id);
  const { update } = useMutate('enrolment/update-subscription-price');

  const { membership } = enrolment;
  const priceIntervalText = `every ${membership.frequency > 1 ? membership.frequency : ''} ${membership.interval}${
    !!(membership.frequency > 1) ? 's' : ''
  }`;

  const handleClose = () => {
    setCoupon(undefined);
    onClose();
  };

  const handleUpdate = () => {
    const data = {
      subscriptionId: enrolment.subscriptionId,
      ...(price !== enrolment.price / 100 && { price: price * 100 }),
      ...(coupon && { coupon }),
    };
    update(data);
    handleClose();
  };
  return (
    <ModalBase title={`Update subscription price`} open={open} onClose={onClose}>
      <>
        <TextField
          label={'price'}
          value={price}
          onChange={i => setPrice(+i.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            endAdornment: <InputAdornment position="end">{priceIntervalText}</InputAdornment>,
          }}
        />
        <TextField
          select
          label={'apply coupon'}
          value={coupon}
          onChange={i => setCoupon(couponsReponse.data?.find(c => c.id === i.target.value))}
        >
          {couponsReponse.data?.map(i => (
            <MenuItem key={i.id} value={i.id}>
              {i.name}
            </MenuItem>
          ))}
        </TextField>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={handleUpdate}>
            Update
          </Button>
        </Box>
      </>
    </ModalBase>
  );
};
