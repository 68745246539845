import { useQuery } from 'react-query';
import axios from 'axios';
import { useSession } from './useSession';
import { utc } from 'utils/time';
import { useUserContext } from 'components/context/user-context';

export type Attendance = {
  id: number;
  // class: number;
  classId: number;
  // user: number;
  userId: number;
  date: string;
  cancelled: boolean;
};

export const useAttendance = (school: number, start: string) => {
  const { session } = useSession();
  const { users } = useUserContext();

  let attendance: Attendance[] = [];
  const queryResponse = useQuery(
    ['attendance', school, start, users.map(i => i.id)],
    async () => await axios.get(`/attendance?start=${utc(start)}`, { headers: { school } }).then(r => r.data),
    {
      enabled: session && !!start,
    }
  );

  if (queryResponse.data) attendance = queryResponse.data;

  return { attendance };
};
