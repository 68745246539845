import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { Member } from 'hooks/useMember';

export const useSchoolMember = (schoolId: number, memberId?: number) => {
  const membersResponse = useQuery<Member, AxiosError>(
    ['member', schoolId, memberId],
    async () => {
      const response = await axios.get(`/admin/members/${memberId}`, { headers: { school: schoolId } });
      return response.data;
    },
    {
      enabled: !!memberId,
    }
  );
  return membersResponse;
};
