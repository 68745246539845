import { Subject } from 'hooks/useSubject';
import { User } from 'hooks/useUser';
import { getAge } from './time';

export const byValidAge = (users: User[], subjects: Subject[]): User[] => {
  const getUserAge = Object.fromEntries(users.map(i => [i.id, getAge(i.birthday)]));
  return users.filter(
    u =>
      u.birthday === null ||
      subjects.some(
        s => (s.minAge === null || getUserAge[u.id] >= s.minAge) && (s.maxAge === null || getUserAge[u.id] < s.maxAge)
      )
  );
};
