import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { Cookie } from './useJwt';

export const useSession = () => {
  const queryClient = useQueryClient();
  const cookie = new Cookie();

  const setCookie = (jwt?: string) => {
    !jwt ? cookie.destroy() : cookie.set(jwt);
    axios.defaults.headers.common.Authorization = `Bearer ${cookie.get()}`;
  };

  const setSession = (jwt?: string) => {
    setCookie(jwt);
    window.location.reload();
  };

  let session = false;
  const { data, isError, isLoading } = useQuery<{ token: string }, AxiosError>(
    ['auth'],
    async () =>
      (
        await axios.get(`/auth`, {
          headers: {
            Authorization: `Bearer ${cookie.get()}`,
          },
        })
      ).data,
    {
      retry: 0,
      enabled: !!cookie.get(),
      onError: () => {
        cookie.destroy();
        window.location.reload();
      },
      onSuccess: () => {
        if (!session) queryClient.invalidateQueries(['user']);
      },
    }
  );

  if (data?.token && !!cookie.get()) {
    const { token: jwt } = data;
    setCookie(jwt);
  }

  session = !!data?.token;

  return { session, setSession, isError, isLoading } as const;
};
