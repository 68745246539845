import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import { CustomModal } from 'components/shared/custom-modal';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { User } from 'hooks/useUser';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { iso } from 'utils/time';
import { CreateUserDto } from 'utils/types';

type Props = {
  user: User;
  onSubmit: (data?: CreateUserDto) => void;
  onClose: () => void;
};

export const UserDetailsModal = ({ user, onSubmit, onClose }: Props) => {
  const { small } = useCustomTheme();
  const [phone, setPhone] = useState(user.phone || '');
  const [emergencyName, setEmergencyName] = useState(user.emergencyContact?.name || '');
  const [emergencyPhone, setEmergencyPhone] = useState(user.emergencyContact?.phone || '');
  const [birthday, setBirthday] = useState<DateTime | null>(user.birthday ? iso(user.birthday) : null);
  const isChild = user.provider === 'parent';
  const userDetailsPresent = !!(isChild || user.phone) && !!user.emergencyContact && !!user.birthday;

  const dataValid = (isChild || phone) && emergencyName && emergencyPhone && birthday;
  const handleSubmit = () => {
    if (!userDetailsPresent && dataValid) {
      const data = {
        id: user.id,
        phone,
        birthday: birthday.toISODate(),
        emergencyContact: { name: emergencyName, phone: emergencyPhone },
        ...(!isChild && { phone }),
      };
      onSubmit(data);
    }
    onSubmit();
  };

  useEffect(() => {
    if (userDetailsPresent) {
      onSubmit();
    }
  }, [user]);

  return (
    <CustomModal open={!!user && !userDetailsPresent} onClose={onClose}>
      <Card
        sx={{
          display: 'flex',
          width: small ? '90vw' : '450px',
          m: 'auto',
          p: 8,
          justifyItems: 'center',
          backgroundColor: grey[100],
        }}
      >
        <Grid container>
          <Typography variant="h3" mb={2}>{`Update details for ${user.firstName}`}</Typography>
          {!user.phone && !isChild && (
            <TextField label={'phone'} value={phone} onChange={e => setPhone(e.target.value)} />
          )}
          {!user.emergencyContact?.name && (
            <TextField
              label={'emergency contact name'}
              value={emergencyName}
              onChange={e => setEmergencyName(e.target.value)}
            />
          )}
          {!user.emergencyContact?.phone && (
            <TextField
              label={'emergency contact phone'}
              value={emergencyPhone}
              onChange={e => setEmergencyPhone(e.target.value)}
            />
          )}
          {!user.birthday && (
            <DatePicker
              label={'birthday'}
              format={'dd/MM/yyyy'}
              onChange={d => {
                setBirthday(d as DateTime);
              }}
              value={birthday}
            />
          )}
          <Box mt={4} width={1} textAlign={'end'}>
            <Button disabled={!dataValid} variant="contained" onClick={handleSubmit}>
              Update
            </Button>
          </Box>
        </Grid>
      </Card>
    </CustomModal>
  );
};
