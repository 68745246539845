import { MenuItem, TextField } from '@mui/material';
import { SubjectModal } from 'components/modals/subject-modal';
import { useSchoolContext } from '../context/school-context';
import { useLocation } from 'hooks/useLocation';

type Props = {
  locations: number[];
  setLocations: (s: number[]) => void;
  create?: boolean;
  placeholder?: string;
};

export const LocationsSelect = ({ locations, setLocations, placeholder, create = true }: Props) => {
  const { school } = useSchoolContext();
  const { locationsResponse } = useLocation(school.id);

  return (
    <>
      <SubjectModal
        open={locations.includes(0)}
        close={i => setLocations(locations.map(l => (l === 0 && i ? i : l)).filter(l => l))}
      />
      <TextField
        label={placeholder || 'locations'}
        select
        SelectProps={{
          fullWidth: true,
          multiple: true,
          value: locations,
          onChange: i => setLocations(i.target.value as number[]),
          MenuProps: {
            sx: { maxHeight: 240 },
          },
        }}
      >
        {create && (
          <MenuItem key={0} value={0}>
            New location
          </MenuItem>
        )}
        {locationsResponse.data?.map(l => (
          <MenuItem key={l.id} value={l.id}>
            {l.description}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
