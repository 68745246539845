import { Box, Button, Card, Typography } from '@mui/material';
import { CustomModal } from 'components/shared/custom-modal';
import { QRCode } from 'react-qrcode-logo';
import logo from '../../../assets/mm-logo.svg';
import { useKioskSetup } from 'hooks/useKioskSetup';
import { useSchoolContext } from 'components/context/school-context';
import CopyToClipboardButton from 'components/shared/copy-to-clipboard';
import { useMutate } from 'hooks/useMutate';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const SetupKiosk = ({ open, onClose }: Props) => {
  const { school } = useSchoolContext();
  const { kiosk } = useKioskSetup(school.id);
  const { create } = useMutate<{ uuid: string }>('admin/reset-kiosk', {
    invalidate: ['kiosk'],
  });

  return (
    <>
      {kiosk && open ? (
        <CustomModal open onClose={onClose}>
          <Card
            sx={{
              m: 'auto',
              p: 6,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'end' }}></Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Typography my={'auto'} variant="h3">
                Scan code
              </Typography>
              <CopyToClipboardButton data={`${process.env.REACT_APP_BASE_URL}kiosk/${kiosk.uuid}`} />
            </Box>
            {kiosk.userAgent && (
              <Box display={'flex'} justifyContent={'center'}>
                <Typography my={'auto'} variant="h3">
                  {`Currently bound to: ${kiosk.userAgent}`}
                </Typography>
              </Box>
            )}
            <Box mt={4}>
              <QRCode
                size={250}
                eyeRadius={10}
                ecLevel="H"
                qrStyle="squares"
                value={`${process.env.REACT_APP_BASE_URL}kiosk/${kiosk.uuid}`}
                logoImage={logo}
                removeQrCodeBehindLogo
                logoPadding={4}
              />
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Button variant="text" onClick={() => create({ uuid: kiosk.uuid })}>
                Reset kiosk
              </Button>
            </Box>
          </Card>
        </CustomModal>
      ) : (
        <></>
      )}
    </>
  );
};
