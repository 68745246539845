import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

export enum Duration {
  FOREVER = 'forever',
  ONCE = 'once',
  REPEATING = 'repeating',
}

export type CreateCouponDto = {
  id?: string;
  amount_off?: number;
  percent_off?: number;
  duration: Duration;
  duration_in_months?: number;
  max_redemptions?: number;
  name: string;
  redeem_by?: number;
  currency: 'aud';
};

export type Coupon = {
  id: string;
  promoCodes: { id: string; code: string }[];
} & CreateCouponDto;

export const useCoupons = (schoolId: number): UseQueryResult<Coupon[]> => {
  const queryResponse = useQuery<any, AxiosError>(
    ['coupon', schoolId],
    async () => {
      const response = await axios.get('/coupon', { headers: { school: schoolId } });
      return response.data;
    },
    {
      enabled: !!schoolId,
    }
  );

  return queryResponse;
};
