import { Box, Typography } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { BarChart } from '@mui/x-charts';
import { CardBase } from 'components/shared/card-base';
import { Transaction } from 'hooks/admin/useTransactions';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { iso } from 'utils/time';
import { grey } from '@mui/material/colors';

type Props = {
  transactions: Transaction[];
};

export const Body = ({ transactions }: Props) => {
  const { small } = useCustomTheme();
  const { school } = useSchoolContext();

  const chargeData = transactions.filter(i => i.reporting_category === 'charge');

  const ranges = new Array(12).fill(0).map((_, i) => {
    const date = DateTime.now().minus({ months: i });
    return {
      date: date.toISODate(),
      value: sumBy(
        chargeData.filter(d => d.created <= date.toSeconds() && d.created > date.minus({ months: 1 }).toSeconds()),
        d => d.amount / 100
      ),
    };
  });

  const series = ranges.reverse().map(i => i.value);

  return (
    <CardBase sx={{ p: 3, height: 320, maxWidth: 600, width: small ? '92vw' : '60vw', m: 3 }}>
      <Box mt={1}>
        <Typography mb={0} color={grey[600]}>
          {'Revenue'}
        </Typography>
        <BarChart
          dataset={ranges}
          yAxis={[
            {
              scaleType: 'linear',
              valueFormatter: (v: number) => {
                return v / 1000 >= 1 ? `$${(v / 1000).toFixed(1)}k` : `$${v}`;
              },
            },
          ]}
          xAxis={[
            {
              scaleType: 'band',
              dataKey: 'date',
              valueFormatter: (v: string) =>
                iso(v).toLocaleString({
                  month: 'short',
                  day: '2-digit',
                }),
            },
          ]}
          colors={[school.priColor]}
          height={260}
          sx={{ pr: 0 }}
          series={[{ valueFormatter: value => `$${value}`, data: series, label: 'Total revenue' }]}
          borderRadius={10}
          margin={{
            left: 40,
            right: 10,
            top: 95,
            bottom: 20,
          }}
          slotProps={{
            legend: {
              hidden: false,
              labelStyle: {
                fontSize: 10,
              },
              itemMarkWidth: 8,
              itemMarkHeight: 8,
              markGap: 5,
              itemGap: 15,
              position: {
                vertical: 'top',
                horizontal: 'left',
              },
            },
          }}
        />
      </Box>
    </CardBase>
  );
};
