import { Modal } from '@mui/material';

type Props = { open: boolean; children: JSX.Element; onClose?: () => void };

export const CustomModal = ({ open, children, onClose }: Props) => (
  <Modal
    open={open}
    onClose={(e, r) => {
      if (r === 'backdropClick') onClose?.();
    }}
    sx={{ backdropFilter: 'rgba(0, 0, 0, 0.2)', display: 'flex' }}
  >
    {children}
  </Modal>
);
