import { Accordion, AccordionDetails, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PaidIcon from '@mui/icons-material/Paid';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { useQueryState } from 'hooks/useQueryState';
import { PageType } from 'utils/types';
import { AuthWrapper } from 'components/auth-wrapper';
import { useRef, useState } from 'react';
import { useUserContext } from 'components/context/user-context';

const CustomIconButton = ({ page, children }: { page: PageType; children: JSX.Element }) => {
  const [query, setQuery] = useQueryState();
  const active = query.page === page;

  return (
    <Box sx={{ bgcolor: active ? 'primary.dark' : 'white', borderRadius: 3, height: 42, width: 42, mt: 1 }}>
      <IconButton
        sx={{ p: 1.5, color: active ? 'white' : 'primary.dark', borderRadius: 3 }}
        onClick={() => setQuery({ page })}
      >
        {children}
      </IconButton>
    </Box>
  );
};

const NavIcon = ({ page, children }: { page: PageType; children: JSX.Element }) => {
  const [query, setQuery] = useQueryState();
  const active = query.page === page;

  return (
    <Box
      color={active ? 'white' : 'primary.dark'}
      sx={{
        textAlign: 'center',
        pt: 1.5,
        bgcolor: active ? 'primary.dark' : 'white',
        borderRadius: 3,
        height: 42,
        width: 42,
      }}
      onClick={() => setQuery({ page })}
    >
      {children}
    </Box>
  );
};

const SideNavIcon = ({ page, icon, children }: { page: PageType; icon: JSX.Element; children?: JSX.Element[] }) => {
  const [query, setQuery] = useQueryState();
  const active = query.page === page;

  return (
    <>
      <Accordion
        disableGutters
        expanded={active}
        sx={{ '&::before': { bgcolor: 'transparent' }, pt: 6, bgcolor: 'transparent', boxShadow: 'none' }}
      >
        <MenuItem sx={{ p: 0, color: active ? 'white' : 'primary.dark' }} onClick={() => setQuery({ page })}>
          <Box display={'flex'} ml={1} my={1}>
            <NavIcon page={page}>{icon}</NavIcon>
            <Typography my={'auto'} ml={2} variant="h3" color={active ? 'primary.dark' : grey[500]}>
              {page}
            </Typography>
          </Box>
        </MenuItem>
        <AccordionDetails sx={{ p: 0, bgcolor: 'transparent', boxShadow: 'none', outline: 'none', border: 'none' }}>
          <Box>{children}</Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const SideNavButton = ({ page, tab }: { page: PageType; tab: string }) => {
  const [query, setQuery] = useQueryState();
  const active = query.page === page && query[`${page} tab`] === tab;

  return (
    <MenuItem
      sx={{ py: 1, color: active ? 'primary.dark' : '', pl: 14 }}
      onClick={() => setQuery({ page, [`${page} tab`]: tab })}
    >
      {tab}
    </MenuItem>
  );
};

export const Navbar = () => {
  const { small } = useCustomTheme();
  const { logout } = useUserContext();
  const [, setQuery] = useQueryState();
  const [userMenu, setUserMenu] = useState(false);
  const userRef = useRef(null);

  return (
    <>
      {small ? (
        <Box
          sx={{ width: 1, flexDirection: 'row', px: 8 }}
          height={200}
          mb={-33}
          display={'flex'}
          justifyContent={'space-between'}
          position={'absolute'}
          bottom={0}
          bgcolor={grey[100]}
          boxShadow={`1px 1px 3px ${grey[500]}`}
          zIndex={888}
        >
          <CustomIconButton page={PageType.home}>
            <HomeIcon sx={{ fontSize: 30 }} />
          </CustomIconButton>
          <CustomIconButton page={PageType.user_settings}>
            <ManageAccountsIcon sx={{ fontSize: 30 }} />
          </CustomIconButton>
          <Box sx={{ bgcolor: 'white', borderRadius: 3, height: 42, width: 42, mt: 1 }}>
            <IconButton sx={{ p: 1.5, borderRadius: 3 }} ref={userRef} onClick={() => setUserMenu(true)}>
              <MoreHorizIcon sx={{ fontSize: 30, color: 'primary.dark' }} />
            </IconButton>
          </Box>
          <Menu
            id="user-menu"
            anchorEl={userRef.current}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={userMenu}
            onClick={() => setUserMenu(false)}
          >
            <MenuItem sx={{ width: '150px' }} onClick={() => setQuery({ page: PageType.home })}>
              Home
            </MenuItem>
            <MenuItem onClick={() => setQuery({ page: PageType.user_settings })}>{PageType.user_settings}</MenuItem>
            <AuthWrapper>
              <MenuItem onClick={() => setQuery({ page: PageType.member_settings })}>
                {PageType.member_settings}
              </MenuItem>
              <MenuItem onClick={() => setQuery({ page: PageType.business_settings })}>
                {PageType.business_settings}
              </MenuItem>
              <MenuItem onClick={() => setQuery({ page: PageType.pricing })}>{PageType.pricing}</MenuItem>
              <MenuItem onClick={() => setQuery({ page: PageType.reporting })}>{PageType.reporting}</MenuItem>
            </AuthWrapper>
            <MenuItem sx={{ width: '150px' }} onClick={logout}>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      ) : (
        <Box
          height={1}
          pt={20}
          sx={{ flexDirection: 'column', pl: 1, overflowY: 'scroll' }}
          width={180}
          display={'flex'}
          position={'absolute'}
          bottom={0}
          bgcolor={grey[100]}
          boxShadow={`1px 1px 4px ${grey[400]}`}
          zIndex={888}
          justifyContent={'space-between'}
        >
          <Box>
            <SideNavIcon page={PageType.home} icon={<HomeIcon sx={{ fontSize: 30 }} />}>
              <SideNavButton page={PageType.home} tab={'Dashboard'} />
              <SideNavButton page={PageType.home} tab={'Timetable'} />
              <SideNavButton page={PageType.home} tab={'Closures'} />
            </SideNavIcon>

            <SideNavIcon page={PageType.user_settings} icon={<ManageAccountsIcon sx={{ fontSize: 30 }} />}>
              <SideNavButton page={PageType.user_settings} tab={'Personal'} />
              <SideNavButton page={PageType.user_settings} tab={'Children'} />
              <SideNavButton page={PageType.user_settings} tab={'Memberships'} />
            </SideNavIcon>

            <AuthWrapper>
              <SideNavIcon page={PageType.member_settings} icon={<PeopleAltIcon sx={{ fontSize: 30 }} />}>
                <SideNavButton page={PageType.member_settings} tab={'Members'} />
                <SideNavButton page={PageType.member_settings} tab={'Grades'} />
              </SideNavIcon>
              <SideNavIcon page={PageType.pricing} icon={<PaidIcon sx={{ fontSize: 30 }} />}>
                <SideNavButton page={PageType.pricing} tab={'Memberships'} />
                <SideNavButton page={PageType.pricing} tab={'Coupons'} />
              </SideNavIcon>

              <SideNavIcon page={PageType.business_settings} icon={<SettingsIcon sx={{ fontSize: 30 }} />}>
                <SideNavButton page={PageType.business_settings} tab={'School'} />
                <SideNavButton page={PageType.business_settings} tab={'Terms'} />
                <SideNavButton page={PageType.business_settings} tab={'Locations'} />
                <SideNavButton page={PageType.business_settings} tab={'Subjects'} />
              </SideNavIcon>
              <SideNavIcon page={PageType.reporting} icon={<QueryStatsIcon sx={{ fontSize: 30 }} />} />
            </AuthWrapper>
          </Box>
          <MenuItem sx={{ mb: 1, mt: 6, p: 0 }} onClick={logout}>
            <Box
              display={'flex'}
              sx={{
                p: 1.5,
                bgcolor: 'white',
                color: 'primary.dark',
                borderRadius: 3,
                height: 42,
                width: 42,
                mt: 1,
                ml: 1,
              }}
            >
              <LogoutIcon sx={{ fontSize: 30 }} />
              <Typography mt={2} ml={3} variant="h3">
                Logout
              </Typography>
            </Box>
          </MenuItem>
        </Box>
      )}
    </>
  );
};
