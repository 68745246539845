import { Card, Grid, Typography, TextField, Box, Button, InputAdornment, IconButton, ButtonBase } from '@mui/material';
import { useReducer, useState } from 'react';
import { useMutate } from '../../../hooks/useMutate';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { CustomModal } from 'components/shared/custom-modal';
import EditIcon from '@mui/icons-material/Edit';
import BinIcon from '@mui/icons-material/Delete';
import { Cohort, CreateCohortDto } from 'hooks/useCohorts';
import { CohortBadge } from 'components/shared/cohort-badge';
import { ColorPicker } from '../color-picker';

type Props = { cohort?: Cohort; open: boolean; onClose: () => void };
type Colors = { val?: string; idx: number };

export const CohortModal = ({ cohort, open, onClose }: Props) => {
  const { create, destroy } = useMutate<CreateCohortDto>('cohort', { onSuccess: onClose, invalidate: ['cohort'] });
  const { small } = useCustomTheme();
  const [name, setName] = useState(cohort?.name || '');
  const [colors, setColors] = useReducer((state: string[], { val, idx }: Colors) => {
    if (!val) {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    }
    state[idx] = val;
    return [...state];
  }, cohort?.colors.split(',') || []);
  const [colorPicker, setColorPicker] = useState(Object.fromEntries(colors.map(i => [colors.indexOf(i), false])));

  const handleCreate = () => {
    create({
      ...(!!cohort && { id: cohort.id }),
      name,
      ...(colors.length && { colors: colors.toString() }),
    });
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <Card
        sx={{
          width: small ? '90vw' : '450px',
          m: 'auto',
          p: 6,
        }}
      >
        <Box>
          <Typography ml={2} variant="h3">
            {cohort ? 'Update grade' : 'New grade'}
          </Typography>
        </Box>
        <Grid item xs={12}>
          <TextField label={'name'} value={name} onChange={e => setName(e.target.value)}></TextField>
        </Grid>
        <Grid container m={2} item xs={12}>
          {!!colors.length && <CohortBadge sx={{ mt: 2, mx: 'auto' }} colors={colors} />}
        </Grid>
        <Grid my={2} item xs={12}>
          {colors.map((color, idx) => (
            <Box display={'flex'} key={idx}>
              <TextField
                label={`Display color`}
                value={color}
                onChange={e => setColors({ val: e.target.value, idx })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setColorPicker({ [idx]: true })}>
                        <EditIcon />
                      </IconButton>
                      <ColorPicker
                        open={colorPicker[idx]}
                        color={color}
                        setColor={val => setColors({ val, idx })}
                        onClose={() => setColorPicker({})}
                      />
                      <ButtonBase onClick={() => setColorPicker({ [idx]: true })}>
                        <Box
                          sx={{ marginRight: -2, width: 140, height: 25, borderRadius: 3, backgroundColor: color }}
                        />
                      </ButtonBase>
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton sx={{ height: 40, mt: 3.5, ml: 2 }} onClick={() => setColors({ val: undefined, idx })}>
                <BinIcon />
              </IconButton>
            </Box>
          ))}
        </Grid>
        <Box ml={2}>
          <Button variant="text" onClick={() => setColors({ val: '#fffff', idx: colors.length })}>
            Add colour
          </Button>
        </Box>
        <Grid mt={6} item xs={12} display={'flex'} justifyContent="space-between">
          <Box>
            {cohort && (
              <Button
                variant="outlined"
                sx={{ justifySelf: 'start' }}
                onClick={() => {
                  destroy(cohort.id);
                  onClose();
                }}
              >
                Delete
              </Button>
            )}
          </Box>
          <Button disabled={!name} onClick={handleCreate} variant="contained">
            {cohort ? 'Save' : 'Create'}
          </Button>
        </Grid>
      </Card>
    </CustomModal>
  );
};
