import { Card, Grid, Typography, TextField, Box, Button, InputAdornment, IconButton, ButtonBase } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutate } from '../../../hooks/useMutate';
import { CreateLocation, useLocation } from '../../../hooks/useLocation';
import { useSchoolContext } from '../../context/school-context';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { CustomModal } from 'components/shared/custom-modal';
import EditIcon from '@mui/icons-material/Edit';
import { Location } from 'utils/types';
import { ColorPicker } from '../color-picker';

type Props = { location?: Location; open: boolean; close: (args?: number) => void };

export const LocationModal = ({ location, open, close }: Props) => {
  const { school } = useSchoolContext();
  const { locationsResponse } = useLocation(school.id);
  const { create, destroy } = useMutate<CreateLocation>('location', { invalidate: ['location'] });
  const { small } = useCustomTheme();
  const [color, setColor] = useState(location?.color || '');
  const [colorPicker, setColorPicker] = useState(false);
  const [address, setAddress] = useState(location?.address || '');
  const [description, setDescription] = useState(location?.description || '');
  const call = useRef<boolean>();

  const existingLocation = useMemo(
    () => locationsResponse.data?.find(s => s.address === address),
    [locationsResponse.data, address]
  );

  const disabled = useMemo(
    () => (!!existingLocation && !location?.id) || !address || !description,
    [address, description, existingLocation]
  );

  useEffect(() => {
    if (call.current) close(existingLocation?.id || 0);
  }, [locationsResponse.data]);

  const handleCreate = () => {
    create({
      id: location?.id,
      schoolId: school.id,
      address,
      description,
      color,
    });
    call.current = true;
  };

  return (
    <CustomModal open={open} onClose={close}>
      <Card
        sx={{
          width: small ? '90vw' : '450px',
          m: 'auto',
          p: 6,
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box>
            <Typography mb={2} variant="h3">
              {location ? 'Update location' : 'New location'}
            </Typography>
          </Box>
        </Box>
        <Grid item xs={12}>
          <TextField
            error={!location?.id && !!existingLocation}
            helperText={!location?.id && !!existingLocation ? 'address must be unique' : ''}
            label={'address'}
            value={address}
            onChange={e => setAddress(e.target.value)}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={'display name'}
            value={description}
            onChange={e => setDescription(e.target.value)}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={`Display color`}
            value={color}
            onChange={e => setColor(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setColorPicker(true)}>
                    <EditIcon />
                  </IconButton>
                  <ColorPicker
                    open={colorPicker}
                    color={color}
                    setColor={setColor}
                    onClose={() => setColorPicker(false)}
                  />
                  <ButtonBase onClick={() => setColorPicker(true)}>
                    <Box sx={{ marginRight: -2, width: 140, height: 25, borderRadius: 3, backgroundColor: color }} />
                  </ButtonBase>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid mt={4} item xs={12} display={'flex'} justifyContent="space-between">
          <Box>
            {location && (
              <Button
                variant="outlined"
                sx={{ justifySelf: 'start' }}
                onClick={() => {
                  destroy(location.id);
                  close();
                }}
              >
                Delete
              </Button>
            )}
          </Box>
          <Button disabled={disabled} onClick={handleCreate} variant="contained">
            {location?.id ? 'Save' : 'Create'}
          </Button>
        </Grid>
      </Card>
    </CustomModal>
  );
};
