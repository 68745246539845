import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

export type School = {
  id: number;
  name: string;
  email: string;
  description: string;
  termsAndConditions: string;
  active: boolean;
  accountId: string;
  priColor: string;
  secColor: string;
  terColor: string;
  logo: string;
  uuid: string;
};

export const useSchool = (id?: string) => {
  return useQuery<School, AxiosError>(
    ['school', id],
    async () => {
      const response = await axios.get(`/school/${id}`);
      return response.data;
    },
    {
      enabled: !!id,
    }
  );
};
