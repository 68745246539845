import { Box, Button, Checkbox, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { CardBase } from 'components/shared/card-base';
import { CustomModal } from 'components/shared/custom-modal';
import { DateTime } from 'luxon';
import { useState } from 'react';

type Props = {
  onSubmit: (data: { trialEnd?: number; requirePayment?: boolean }) => void;
};

export const StartTrialModal = ({ onSubmit }: Props) => {
  const [open, setOpen] = useState(false);
  const [endDate, setEndDate] = useState<DateTime>(DateTime.now().plus({ days: 2, hours: 1 }));
  const [requirePayment, setRequirePayment] = useState<boolean>(false);

  const handleSubmit = () => {
    onSubmit({ trialEnd: endDate.toSeconds(), requirePayment });
    setOpen(false);
  };

  return (
    <>
      <CustomModal open={open} onClose={() => setOpen(false)}>
        <Box m={'auto'} display={'flex'}>
          <CardBase sx={{ p: 6, m: 'auto' }}>
            <Typography variant="h3">Add free trial period</Typography>
            <Box>
              <DatePicker
                label={'payment start date'}
                format={'dd/MM/yyyy'}
                minDate={DateTime.now().plus({ days: 2 })}
                onChange={d => {
                  setEndDate(d as DateTime);
                }}
                value={endDate}
                sx={{ mt: 6 }}
              />
              <Box display={'flex'} mt={2}>
                <Box>
                  <Checkbox checked={!requirePayment} onChange={e => setRequirePayment(!e.currentTarget.checked)} />
                </Box>
                <Typography my={'auto'} pl={2}>
                  Payment details at trial end
                </Typography>
              </Box>
            </Box>
            <Box mt={6} textAlign={'end'}>
              <Button variant="contained" onClick={handleSubmit}>
                Start trial
              </Button>
            </Box>
          </CardBase>
        </Box>
      </CustomModal>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Sign up with trial
      </Button>
    </>
  );
};
