import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSchoolContext } from 'components/context/school-context';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { DateTime } from 'luxon';
import { contrast } from 'Theme';

type Props = {
  value: DateTime | null;
  onChange: (v: DateTime | null) => void;
};

export const BirthdayPicker = ({ value, onChange }: Props) => {
  const { cardWidth } = useCustomTheme();
  const { school } = useSchoolContext();

  return (
    <Box>
      <DatePicker
        format="dd/MM/yyyy"
        sx={{
          width: cardWidth,
          '&& .MuiInput-root': {
            borderRadius: 0,
            backgroundColor: 'transparent',
            color: contrast(school.priColor),
          },
          '&& .MuiSvgIcon-root': { color: contrast(school.priColor) },
          '&& .MuiInputBase-root::before': {
            borderBottom: `2px solid ${contrast(school.priColor)}`,
          },
          '&& .MuiFormLabel-root': {
            color: contrast(school.priColor),
          },
        }}
        label={'Birthday'}
        value={value}
        onChange={v => onChange(v)}
        slotProps={{
          textField: {
            variant: 'standard',
          },
        }}
      />
    </Box>
  );
};
