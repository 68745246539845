import { Modal, Card, Grid, Typography, TextField, InputAdornment, Box, Button, Checkbox } from '@mui/material';
import { SubjectSelect } from 'components/shared/subject-select';
import { useMutate } from 'hooks/useMutate';
import { useMemo, useState } from 'react';
import { CreateClassPassDto } from './types';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { Membership } from 'hooks/useMemership';
import { LocationsSelect } from 'components/shared/locations-select';

type Props = {
  open: boolean;
  onClose: () => void;
  membership?: Membership;
};

export const ClassPassModal = ({ open, onClose, membership }: Props) => {
  const { small } = useCustomTheme();
  const { create, update, destroy } = useMutate<CreateClassPassDto>('membership', {
    invalidate: ['membership'],
    onSuccess: () => {
      onClose();
    },
  });
  const [subjects, setSubjects] = useState<number[]>(membership?.subjects?.map(m => m.id) || []);
  const [locations, setLocations] = useState(membership?.locations.map(l => l.id) || []);
  const [name, setName] = useState<string>(membership?.name || '');
  const [description, setDescription] = useState<string>(membership?.description || '');
  const [price, setPrice] = useState<number>((membership && membership.price / 100) || NaN);
  const [attendanceLimit, setAttendanceLimit] = useState<number>(membership?.attendanceLimit || 0);
  const [hidden, setHidden] = useState<boolean>(membership?.hidden || false);

  const disabled = useMemo(
    () => !subjects.length || !locations.length || !name || !price || !attendanceLimit,
    [subjects, locations, name, price, attendanceLimit]
  );

  const handleSubmit = () => {
    const classPassDto: CreateClassPassDto = {
      ...(membership && membership),
      type: 'class_pass',
      name,
      description,
      price: price * 100,
      subjects,
      locations,
      attendanceLimit,
      hidden,
    };
    membership ? update(classPassDto) : create(classPassDto);
    onClose();
  };

  return (
    <Modal open={open} sx={{ display: 'flex' }} onClose={onClose}>
      <Card
        sx={{
          width: small ? '90vw' : '450px',
          m: 'auto',
          p: 6,
          justifyItems: 'center',
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography mb={2} variant="h3">
            {membership ? 'Edit class pass' : 'New class pass'}
          </Typography>
        </Box>
        <TextField label={'class pass name'} value={name} onChange={e => setName(e.target.value)} />
        <SubjectSelect subjects={subjects} setSubjects={setSubjects} />
        <LocationsSelect locations={locations} setLocations={setLocations} />
        <TextField label={'description'} value={description} onChange={e => setDescription(e.target.value)} />
        <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
          <TextField
            type="number"
            label={'price'}
            value={price || ''}
            onChange={e => setPrice(Number(e.target.value))}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            sx={{ mr: 3 }}
          />
          <TextField
            type="number"
            label={'attendance limit'}
            value={attendanceLimit || ''}
            onChange={e => setAttendanceLimit(Number(e.target.value))}
            sx={{ ml: 3 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!!attendanceLimit && `session${attendanceLimit > 1 ? 's' : ''}`}
                </InputAdornment>
              ),
              inputProps: { min: 0 },
            }}
          />
        </Box>
        <Box display={'flex'} my={2} textAlign={'center'}>
          <Checkbox checked={hidden} onChange={i => setHidden(i.currentTarget.checked)} />
          <Typography my={'auto'} ml={2} variant="body1">
            {'Hide from members'}
          </Typography>
        </Box>
        <Grid mt={4} item xs={12} display={'flex'} justifyContent="space-between">
          {membership && (
            <Button variant="outlined" onClick={() => destroy(membership.id)}>
              Delete
            </Button>
          )}
          <Button disabled={disabled} onClick={handleSubmit} variant="contained">
            {membership ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Card>
    </Modal>
  );
};
