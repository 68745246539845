import { Card, Typography, Box, Button, CircularProgress } from '@mui/material';
import { CustomModal } from 'components/shared/custom-modal';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useRef, useState } from 'react';
import { useMutate } from 'hooks/useMutate';
import { useSchoolContext } from 'components/context/school-context';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  userId?: number;
  onClose: (arg?: boolean) => void;
};

export const TermsAndConditionsModal = ({ userId, onClose }: Props) => {
  const { small } = useCustomTheme();
  const { school } = useSchoolContext();
  const { create } = useMutate('/member/sign-terms', {
    invalidate: ['member'],
    onSuccess: () => {
      onClose(true);
    },
  });
  const [totalPages, setTotalPages] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const [read, setRead] = useState(false);
  const [scale] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
  };

  const handleScroll = () => {
    if (ref.current) {
      const el = ref.current;
      if (el.scrollHeight - el.scrollTop - el.clientHeight / 2 < el.clientHeight) {
        setRead(true);
      }
    }
  };

  return (
    <>
      <CustomModal open={!!userId} onClose={onClose}>
        <Card
          sx={{
            width: small ? 400 : 600,
            m: 'auto',
            p: 2,
            justifyItems: 'center',
            height: '80vh',
          }}
        >
          <Box
            onScroll={handleScroll}
            ref={ref}
            sx={{
              width: small ? 390 : 600,
              height: '80vh',
              overflow: 'auto',
              pb: 16,
            }}
          >
            <Box display={'flex'} justifyContent={'space-between'} mt={3} ml={3}>
              <Typography variant="h3">Terms & Conditions</Typography>
            </Box>
            <Document
              loading={
                <Box width={1} height={'80vh'} display={'flex'}>
                  <CircularProgress sx={{ margin: 'auto' }} />
                </Box>
              }
              file={school.termsAndConditions}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(totalPages), (el, index) => (
                <Page width={small ? 390 : 600} scale={scale} key={index} pageNumber={index + 1} />
              ))}
            </Document>
            <Button></Button>
          </Box>
          <Box mt={-18} zIndex={99} ml={-6} width={1} textAlign={'end'}>
            <Button disabled={!read} variant="contained" sx={{ ml: 6 }} onClick={() => create({ userId })}>
              {'Accept'}
            </Button>
          </Box>
        </Card>
      </CustomModal>
    </>
  );
};
