import axios, { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { toIso, utc } from 'utils/time';
import { Location } from 'utils/types';
import { useSession } from './useSession';
import { Subject } from './useSubject';

export type Session = {
  id: number;
  subject: Subject[];
  location: Location;
  description: string;
  dateTime: string;
  duration: number;
  frequency: number;
  endDate: string | null;
  attendance: number[];
  week: number;
};

type QueryResult<T> = {
  classes: Array<T>;
};

export const useClass = (schoolId: number, start?: string, end?: string): QueryResult<Session> => {
  const { session } = useSession();

  const startTime = !start ? toIso(DateTime.now().toISODate()) : start;
  const endTime = !end ? toIso(DateTime.now().plus({ days: 14 }).toISODate()) : end;

  let classes: Session[] = [];
  const queryResponse = useQuery<any, AxiosError>(
    ['class', start, end],
    async () => {
      const response = await axios.get(`/class/?start=${utc(startTime)}&end=${utc(endTime)}`, {
        headers: { school: schoolId },
      });
      return response.data;
    },
    {
      enabled: session,
    }
  );

  if (queryResponse?.data) classes = queryResponse?.data;

  return { classes };
};
