import { useMutation, useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useSession } from './useSession';
import { Location } from '../utils/types';

export type CreateLocation = {
  id?: number;
  schoolId: number;
  address: string;
  description: string;
  color: string;
};

export const useLocation = (schoolId: number) => {
  const { session } = useSession();

  const locationsResponse = useQuery<Location[], AxiosError>(
    ['location', session],
    async () => {
      const response = await axios.get(`/location`, { headers: { school: schoolId } });
      return response.data;
    },
    {
      enabled: session,
    }
  );

  const { mutate: create } = useMutation((id: number) => axios.post('/location', { membershipId: id }), {});

  const { mutate: destroy } = useMutation((id: number) => axios.delete(`/location/${id}`), {});

  return { create, destroy, locationsResponse };
};
