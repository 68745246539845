import { Card, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCustomTheme } from 'hooks/useCustomTheme';

export const CardBase = ({ children, sx = {} }: { children: JSX.Element[] | JSX.Element; sx?: SxProps }) => {
  const { cardWidth } = useCustomTheme();

  return (
    <Card
      sx={{
        boxShadow: `0.5px 1px 1px ${grey[400]}`,
        width: cardWidth,
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};
