import { Card, CardActionArea, Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

type Props = {
  name: string;
  onClick: () => void;
  active?: boolean;
};

export const CheckInButton = ({ name, active, onClick }: Props) => {
  return (
    <Card
      sx={{
        mt: 6,
        boxShadow: `0.5px 1px 3px ${grey[400]}`,
        height: 50,
        bgcolor: active ? 'white' : 'primary.main',
      }}
    >
      <CardActionArea onClick={onClick} sx={{ px: 1, height: 50 }}>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Box borderRadius={50} bgcolor={'primary.main'} sx={{ display: 'flex', width: 40, height: 40, pl: 3 }}>
            <Typography fontSize={26} fontWeight={800} color={'white'}>
              {active ? <span>&#10008;</span> : <span>&#10003;</span>}
            </Typography>
          </Box>
          <Typography m={'auto'} pr={6} variant="h3" color={active ? '' : 'white'}>
            {active ? 'Cancel' : `Check in ${name}`}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};
