import { useQueryState } from 'hooks/useQueryState';
import { Members } from './members';
import { Grid } from '@mui/material';
import { PageType, Tabs } from 'utils/types';
import { Cohorts } from './cohorts';
import { AuthWrapper } from 'components/auth-wrapper';
import { Enrolments } from './Enrolments';

export const MemberSettings = () => {
  const [query] = useQueryState();
  const currentTab = query[`${query.page} tab`];
  const tab = (n: number): boolean => currentTab === Tabs[PageType.member_settings][n];

  return (
    <AuthWrapper>
      <Grid container justifyContent={'center'} height={'94.5vh'} overflow={'scroll'}>
        {tab(0) && <Members />}
        {tab(1) && <Enrolments />}
        {tab(2) && <Cohorts />}
      </Grid>
    </AuthWrapper>
  );
};
