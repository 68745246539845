import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Location } from 'utils/types';
import { Subject } from './useSubject';
import { Kiosk } from './useKiosk';

export type Session = {
  id: number;
  subject: Subject[];
  location: Location;
  description: string;
  dateTime: string;
  duration: number;
  frequency: number;
  endDate: string | null;
  attendance: number[];
  week: number;
};

export const useKioskSessions = (kiosk: Kiosk): { sessions: Session[] } => {
  let sessions: Session[] = [];
  const queryResponse = useQuery<any, AxiosError>(
    ['kiosk-class', kiosk],
    async () => {
      const response = await axios.get(`/kiosk/sessions`, {
        headers: { uuid: kiosk.uuid, token: kiosk.token },
      });
      return response.data;
    },
    {
      enabled: !!kiosk,
    }
  );

  if (queryResponse?.data) sessions = queryResponse?.data;

  return { sessions };
};
