import { Box } from '@mui/material';
import { useRef } from 'react';
import { DateTime } from 'luxon';
import { Session, useClass } from '../../../hooks/useClass';
import { toIso } from 'utils/time';
import { useAttendance } from 'hooks/useAttendance';
import { useSchoolContext } from '../../context/school-context';
import { useClosure } from 'hooks/useClosure';
import { Birthdays } from './birthdays';
import { Classes } from './classes';
import { AuthWrapper } from 'components/auth-wrapper';

export type Schedule = {
  date: string;
  sessions: Session[];
};
export const Dashboard = () => {
  const { school } = useSchoolContext();

  const start = useRef(toIso(DateTime.now().toISODate()));
  const end = useRef(toIso(DateTime.now().plus({ days: 14 }).toISODate()));

  const { classes } = useClass(school.id, start.current, end.current);
  const { attendance } = useAttendance(school.id, start.current);
  const { data: closures } = useClosure(school.id);

  return (
    <Box pt={20} maxHeight={1}>
      <Box display={'flex'} flexWrap={'wrap'} overflow={'scroll'} justifyContent={'center'}>
        <Classes classes={classes} attendance={attendance} closures={closures} />
        <AuthWrapper>
          <Birthdays />
        </AuthWrapper>
      </Box>
    </Box>
  );
};
