import { IconButton as Button } from '@mui/material';
import { grey } from '@mui/material/colors';

export const IconButton = ({ action, children }: { action: string; children: JSX.Element }) => {
  return (
    <Button
      sx={{
        m: 1,
        bgcolor: 'white',
        ':hover': {
          bgcolor: grey[200],
        },
      }}
      size="small"
      href={action}
    >
      {children}
    </Button>
  );
};
