import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { useSession } from './useSession';
import { useUserContext } from 'components/context/user-context';
import { User } from './useUser';
import { Cohort } from './useCohorts';
import { School } from './useSchool';
import { Membership } from './useMemership';
import { Status } from 'utils/types';

export const key = 'member';

export type Enrolment = {
  userId: number;
  schoolId: number;
  membershipId: number;
  user: User;
  membership: Membership;
  status: Status;
  invoiceId: string;
  subscriptionId: string;
  attendance: number;
  active: boolean;
  endDate: string | null;
  cancelDate: string | null;
  resumesAt: string | null;
  price: number;
  actualPrice: number;
  futureDiscounts: string[];
  billingCycle: string;
  loading?: boolean;
  groupLimit: number;
  groupMembers: Member[];
  member?: Member;
};

export type UpdateEnrolmentDto = {
  userId: number;
  enrolment: Enrolment;
  subscription?: {
    cancel_at_period_end?: boolean;
    pause_collection?: {
      behavior?: 'keep_as_draft';
      resumes_at?: number;
    } | null;
  };
};

export type Member = {
  userId: number;
  user: User;
  schoolId: number;
  school?: School;
  cohorts: Cohort[];
  status: string;
  lastAttendance: string;
  lastLogin: string;
  notifyAdmin: boolean;
  active: boolean;
  enrolments: Enrolment[];
  children: Member[];
  isAdmin: boolean;
  signedTerms: boolean;
  groupEnrolments: Enrolment[];
};

export const useMember = (school?: number): UseQueryResult<Member> => {
  const { users, user } = useUserContext();
  const { session } = useSession();

  return useQuery(
    [key, school, user.id, users.map(i => i.id), 'attendance'],
    async () => {
      const response = await axios.get(`/${key}`, { headers: { ...(school && { school }) } });
      return response.data;
    },
    {
      enabled: session && !!school,
    }
  );
};
