import { Box, Button, CircularProgress } from '@mui/material';
import { UpdateSubscriptionModal } from 'components/admin/member-settings/update-subscription-modal';
import { useUserContext } from 'components/context/user-context';
import { DateSelectModal } from 'components/modals/date-select-modal';
import { Enrolment, UpdateEnrolmentDto } from 'hooks/useMember';
import { useMutate } from 'hooks/useMutate';
import { User } from 'hooks/useUser';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { CancelableStatus, ResumableStatus, SubscriptionParams } from 'utils/types';

export const AdminUpdateButton = ({ enrolment, user }: { enrolment: Enrolment; user: User }) => {
  const queryClient = useQueryClient();
  const [subscriptionUpdateOpen, setSubscriptionUpdateOpen] = useState(false);
  const [updateType, setUpdateType] = useState<'pause' | 'cancel'>('cancel');

  const { user: contextUser } = useUserContext();
  const [open, setOpen] = useState(false);

  // TODO replace this with a call to batch-update-subscription
  const { update } = useMutate<UpdateEnrolmentDto>('enrolment/cancel-subscription', {
    onSuccess: () => {
      queryClient.invalidateQueries(['enrolment']);
      queryClient.invalidateQueries(['member']);
    },
    headers: { impersonator: contextUser.id },
  });

  const { update: bulkUpdate } = useMutate<{ subscriptionIds: string[]; data: SubscriptionParams }>(
    'enrolment/batch-update-subscription'
  );

  const handleSubscriptionUpdate = (data?: SubscriptionParams) => {
    if (data) {
      enrolment.loading = true;
      bulkUpdate({
        subscriptionIds: [enrolment.subscriptionId],
        data: data,
      });
    }
    setSubscriptionUpdateOpen(false);
  };

  const handleUpdate = (date?: number) => {
    setOpen(false);
    update({
      userId: user.id,
      enrolment: enrolment,
      cancelDate: date,
    });
    enrolment.loading = true;
  };

  const handleClick = () => {
    if (CancelableStatus.includes(enrolment.status)) {
      setOpen(true);
    } else {
      handleUpdate();
    }
  };

  return (
    <>
      <UpdateSubscriptionModal open={subscriptionUpdateOpen} onClose={handleSubscriptionUpdate} type={updateType} />
      <DateSelectModal
        name="Cancellation date"
        buttonName="Cancel"
        open={open}
        onClose={() => setOpen(false)}
        handleUpdate={handleUpdate}
      />
      {enrolment.loading ? (
        <CircularProgress size={22} sx={{ mb: -3 }} />
      ) : (
        <Box display={'flex'}>
          {(enrolment.cancelDate || enrolment.resumesAt) && ResumableStatus.includes(enrolment.status) ? (
            <Button variant="outlined" onClick={handleClick}>
              Resume
            </Button>
          ) : (
            <>
              <Button onClick={handleClick} sx={{ ml: 2 }} variant="outlined">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setUpdateType('pause');
                  setSubscriptionUpdateOpen(true);
                }}
                sx={{ ml: 2 }}
                variant="outlined"
              >
                Pause
              </Button>
            </>
          )}
        </Box>
      )}
    </>
  );
};
