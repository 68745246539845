import { Box } from '@mui/system';
import { useSchoolContext } from 'components/context/school-context';
import { InputField } from './input-field';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { BirthdayPicker } from './date-picker';
import { grey } from '@mui/material/colors';
import { useMutate } from 'hooks/useMutate';
import { WaveBorder } from './wave-border';
import { Footer } from './footer';
import { Button, Typography } from '@mui/material';
import { contrast } from 'Theme';
import { useUserContext } from 'components/context/user-context';

export const ChildrenDetails = () => {
  const { school } = useSchoolContext();
  const { user } = useUserContext();
  const [addChildren, setAddChildren] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState<DateTime | null>(null);

  const anyField = !!(firstName || lastName || birthday);
  const allFields = !!(firstName && lastName && birthday);

  const { update } = useMutate('user', {
    invalidate: ['user'],
  });

  const { create } = useMutate('user/child', {
    onSuccess: () => {
      setFirstName('');
      setLastName('');
      setBirthday(null);
    },
  });

  const handleBack = () => {
    const data = {
      id: user.id,
      onboarding: { ...user.onboarding, children: false, emergencyContact: false },
    };
    update(data);
  };

  const handleCreate = () => {
    if (allFields) {
      const data = {
        firstName,
        lastName,
        birthday: birthday?.toISODate(),
        emergencyContact: { name: `${user.firstName} ${user.lastName}`, phone: user.phone || '' },
        onboarding: { ...user.onboarding, user: true },
      };
      create(data);
    }
  };

  const handleUpdate = () => {
    const data = {
      id: user.id,
      onboarding: { ...user.onboarding, children: true },
    };
    update(data);
  };

  return (
    <>
      {!addChildren ? (
        <Box display={'flex'} width={1} justifyContent={'center'}>
          <Box width={1}>
            <Box sx={{ display: 'flex', textAlign: 'center' }} height={'14vh'} bgcolor={grey[100]}>
              <Box width={100} m={'auto'} component={'img'} src={school.logo} borderRadius={'50%'} />
            </Box>
            <WaveBorder color={school.priColor} />
            <Box
              flexDirection={'column'}
              display={'flex'}
              width={1}
              textAlign={'center'}
              height={'80vh'}
              pb={40}
              bgcolor={school.priColor}
              color={contrast(school.priColor)}
            >
              <Box m={'auto'} p={10}>
                <Typography fontSize={30}>Do you have any children that will be training at the club?</Typography>
                <Box pt={10} m={'auto'} width={250} display={'flex'} justifyContent={'space-around'}>
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: 20,
                      width: 100,
                      color: contrast(school.priColor),
                      borderColor: contrast(school.priColor),
                    }}
                    onClick={() => setAddChildren(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: 20,
                      width: 100,
                      color: contrast(school.priColor),
                      borderColor: contrast(school.priColor),
                    }}
                    onClick={() => {
                      handleUpdate();
                    }}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </Box>
            <Footer onClick={handleUpdate} buttonText={'Next >'} back={handleBack} stage={3} />
          </Box>
        </Box>
      ) : (
        <Box display={'flex'} width={1} justifyContent={'center'}>
          <Box width={1}>
            <Box sx={{ display: 'flex', textAlign: 'center' }} height={'14vh'} bgcolor={grey[100]}>
              <Box width={100} m={'auto'} component={'img'} src={school.logo} borderRadius={'50%'} />
            </Box>
            <WaveBorder color={school.priColor} />
            <Box
              flexDirection={'column'}
              display={'flex'}
              width={1}
              textAlign={'center'}
              height={'80vh'}
              pb={40}
              bgcolor={school.priColor}
              color={contrast(school.priColor)}
            >
              <Box m={'auto'}>
                <Typography variant="h1">Add child</Typography>
                <InputField label={'First name'} value={firstName} onChange={v => setFirstName(v)} />
                <InputField label={'Last name'} value={lastName} onChange={v => setLastName(v)} />
                <BirthdayPicker value={birthday} onChange={v => setBirthday(v)} />
                <Box mt={10}>
                  <Button
                    variant="text"
                    sx={{
                      fontSize: 20,
                      width: 200,
                      color: contrast(school.priColor),
                      borderColor: contrast(school.priColor),
                    }}
                    onClick={handleCreate}
                    disabled={!firstName || !lastName || !birthday}
                  >
                    + Add another child
                  </Button>
                </Box>
              </Box>
            </Box>
            <Footer
              onClick={() => {
                handleCreate();
                handleUpdate();
              }}
              back={handleBack}
              buttonText={'Done >'}
              disabled={anyField && !allFields}
              stage={4}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
