import { Box } from '@mui/system';
import { useSchoolContext } from 'components/context/school-context';
import { InputField } from './input-field';
import { useState } from 'react';
import { grey } from '@mui/material/colors';
import { useMutate } from 'hooks/useMutate';
import { useUserContext } from 'components/context/user-context';
import { WaveBorder } from './wave-border';
import { Footer } from './footer';
import { Typography } from '@mui/material';
import { contrast } from 'Theme';

export const EmergencyContact = () => {
  const { school } = useSchoolContext();
  const { user } = useUserContext();
  const [emergencyContact, setEmergencyContact] = useState(user.emergencyContact?.name || '');
  const [emergencyPhone, setEmergencyPhone] = useState(user.emergencyContact?.phone || '');

  const { update } = useMutate('user', {
    invalidate: ['user'],
  });

  const handleSubmit = () => {
    const data = {
      id: user.id,
      emergencyContact: { name: emergencyContact, phone: emergencyPhone },
      onboarding: { ...user.onboarding, emergencyContact: true },
    };
    update(data);
  };

  const handleBack = () => {
    const data = {
      id: user.id,
      onboarding: { ...user.onboarding, children: false, user: false },
    };
    update(data);
  };

  return (
    <Box display={'flex'} width={1} justifyContent={'center'}>
      <Box width={1}>
        <Box sx={{ display: 'flex', textAlign: 'center' }} height={'14vh'} bgcolor={grey[100]}>
          <Box width={100} m={'auto'} component={'img'} src={school.logo} borderRadius={'50%'} />
        </Box>
        <WaveBorder color={school.priColor} />
        <Box
          flexDirection={'column'}
          display={'flex'}
          width={1}
          textAlign={'center'}
          height={'80vh'}
          pb={20}
          bgcolor={school.priColor}
          color={contrast(school.priColor)}
          overflow={'auto'}
        >
          <Box m={'auto'}>
            <Typography mb={10} variant="h1">
              Emergency contact
            </Typography>
            <InputField
              label={'Emergency contact name'}
              value={emergencyContact}
              onChange={v => setEmergencyContact(v)}
            />
            <InputField label={'Emergency contact phone'} value={emergencyPhone} onChange={v => setEmergencyPhone(v)} />
          </Box>
        </Box>
        <Footer
          onClick={() => {
            handleSubmit();
          }}
          back={handleBack}
          buttonText={'Next >'}
          disabled={!emergencyContact || !emergencyPhone}
          stage={3}
        />
      </Box>
    </Box>
  );
};
