import { Box } from '@mui/material';
import { AuthWrapper } from 'components/auth-wrapper';
import { IncomeChart } from './income-chart';
import { AttendanceChart } from './attendance-chart';
import { MembersChart } from './members-chart';

export const Admin = () => {
  return (
    <AuthWrapper>
      <Box height={'93vh'} alignContent={'center'} overflow={'auto'} mb={30}>
        <Box justifyContent={'center'} mb={30}>
          <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
            <MembersChart />
            <AttendanceChart />
            <IncomeChart />
          </Box>
        </Box>
      </Box>
    </AuthWrapper>
  );
};
