import * as ReactDOMClient from 'react-dom/client';
import React from 'react';
import reportWebVitals from './reportWebVitals';
import App from 'App';
import axios from 'axios';
import { QueryProvider } from './api/query';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import './index.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container as HTMLElement);

const BASE_URL = process.env.REACT_APP_SERVER;

const setupAxios = () => {
  axios.defaults.baseURL = BASE_URL;
};

setupAxios();

root.render(
  <React.StrictMode>
    <QueryProvider>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LocalizationProvider>
    </QueryProvider>
  </React.StrictMode>
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
