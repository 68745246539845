import { Box, Typography } from '@mui/material';
import { CardBase } from 'components/shared/card-base';
import { DateTime } from 'luxon';
import background from './../../../../assets/birthday-bg.svg';
import { useSchoolContext } from 'components/context/school-context';
import { Carousel } from '../carousel';
import { DisplayImage } from 'components/shared/display-image';
import { useCustomTheme } from 'hooks/useCustomTheme';
import CakeIcon from '@mui/icons-material/Cake';
import TextsmsIcon from '@mui/icons-material/Textsms';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { contrast } from 'Theme';
import { IconButton } from './icon-button';
import { useSchoolMembers } from 'hooks/admin/useSchoolMembers';

export const Birthdays = () => {
  const { school } = useSchoolContext();
  const { cardWidth } = useCustomTheme();
  const { data: members } = useSchoolMembers(school.id);

  if (!members) return null;

  const now = DateTime.now().toFormat('MM-dd');

  const birthdays = members.filter(member => {
    return member.user.birthday?.substring(5, 10) === now;
  });

  return (
    <CardBase sx={{ height: 'fit-content', m: 3 }}>
      <Box bgcolor={`${school.priColor}`}>
        <Box
          sx={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        >
          <Box color={contrast(school.priColor)} display={'flex'} p={3}>
            <CakeIcon sx={{ fontSize: 30 }} />
            <Typography variant="h3" mt={3} ml={2}>
              Birthdays
            </Typography>
          </Box>
          <Carousel height={40} color={school.priColor}>
            {birthdays.map(({ user }, idx) => (
              <Box
                key={`g${idx}`}
                sx={{
                  scrollSnapAlign: 'center',
                }}
              >
                <Box sx={{ pl: 3, width: cardWidth }}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'}>
                      <DisplayImage firstName={user.firstName} picture={user.picture} size={36} />
                      <Typography color={contrast(school.priColor)} m={3} variant="h2">{`${
                        user.firstName
                      }. ${user.lastName.substring(0, 1).toUpperCase()}`}</Typography>
                    </Box>
                    <Box mr={3}>
                      <IconButton action={`mailto:${user.email}`}>
                        <EmailRoundedIcon color={'primary'} />
                      </IconButton>
                      <IconButton action={`tel:/${user.phone}`}>
                        <LocalPhoneRoundedIcon color={'primary'} />
                      </IconButton>
                      <IconButton action={`sms:/${user.phone}`}>
                        <TextsmsIcon color={'primary'} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Carousel>
        </Box>
      </Box>
    </CardBase>
  );
};
