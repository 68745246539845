import { Grid, Box, Typography, Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import { useMembership } from '../../hooks/useMemership';
import { AuthWrapper } from 'components/auth-wrapper';
import { MembershipModal } from 'components/modals/membership-modal';
import { ClassPassModal } from '../modals/class-pass-modal/index';
import { MembershipCard } from './membership-card';
import { useSchoolContext } from '../context/school-context';
import { useQueryState } from 'hooks/useQueryState';
import { PageType } from 'utils/types';
import { sortBy } from 'lodash';

export const Memberships: React.FC = () => {
  const {
    school,
    account: { charges_enabled, payouts_enabled },
  } = useSchoolContext();
  const [classPassModal, setClassPassModal] = useState(false);
  const [, setQuery] = useQueryState();
  const [membershipModal, setMembershipModal] = useState(false);
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  const useMembershipResponse = useMembership(school.id);
  const accountActive = charges_enabled && payouts_enabled;

  return (
    <>
      <MembershipModal open={membershipModal} onClose={() => setMembershipModal(false)} />
      <ClassPassModal open={classPassModal} onClose={() => setClassPassModal(false)} />
      <Grid container direction={'column'} m={'auto'}>
        <Grid container justifyContent="center">
          <Box
            sx={{
              overflow: 'scroll',
              display: 'flex',
              scrollSnapType: 'x mandatory',
            }}
            ref={ref}
            pr={50}
            onMouseDown={onMouseDown}
          >
            {useMembershipResponse.data?.length ? (
              sortBy(useMembershipResponse.data, m => m.id).map(m => <MembershipCard membership={m} key={m.id} />)
            ) : (
              <Box mb={4}>
                <Typography variant="h2">No active memberships</Typography>
                {!accountActive && (
                  <Button variant="text" onClick={() => setQuery({ page: PageType.business_settings, tab: '' })}>
                    Configure Stripe to enable memberships
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid sx={{ width: '100%', textAlign: 'center' }}>
          <AuthWrapper>
            <Button
              variant="outlined"
              disabled={!accountActive}
              onClick={() => setMembershipModal(true)}
              sx={{ mr: 2 }}
            >
              Create membership
            </Button>
            <Button
              variant="outlined"
              disabled={!accountActive}
              onClick={() => setClassPassModal(true)}
              sx={{ px: 5, ml: 2 }}
            >
              Create class pass
            </Button>
          </AuthWrapper>
        </Grid>
      </Grid>
    </>
  );
};
