import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { LineChart } from '@mui/x-charts';
import { useSchoolContext } from 'components/context/school-context';
import { CardBase } from 'components/shared/card-base';
import { MemberTracking } from 'hooks/admin/useMemberTracking';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { iso } from 'utils/time';

type Props = {
  memberTracking: MemberTracking[];
};

export const Body = ({ memberTracking }: Props) => {
  const { small } = useCustomTheme();
  const { school } = useSchoolContext();

  const memberTrackingData = [
    { label: 'active members', showMark: false, data: memberTracking.map(i => i.active) },
    { label: 'inactive members', showMark: false, data: memberTracking.map(i => i.inactive) },
    {
      label: 'prospective members',
      showMark: false,
      data: memberTracking.map(i => i.prospect),
    },
  ];

  return (
    <CardBase sx={{ p: 3, height: 320, maxWidth: 600, width: small ? '92vw' : '60vw', m: 3 }}>
      <Box mt={1}>
        <Typography mb={1} color={grey[600]}>
          {'Members'}
        </Typography>
        <LineChart
          dataset={memberTracking.map(i => ({ date: i.date }))}
          yAxis={[
            {
              scaleType: 'linear',
            },
          ]}
          xAxis={[
            {
              scaleType: 'band',
              dataKey: 'date',
              valueFormatter: (v: string) =>
                iso(v).toLocaleString({
                  month: 'short',
                  day: '2-digit',
                }),
            },
          ]}
          colors={[school.priColor, '#ffaf02', '#d000ff']}
          height={260}
          sx={{ pr: 0, '&&': { touchAction: 'none', MozWindowDragging: 'drag' } }}
          series={memberTrackingData}
          margin={{
            left: 40,
            right: 10,
            top: 95,
            bottom: 20,
          }}
          slotProps={{
            legend: {
              labelStyle: {
                fontSize: 10,
              },
              itemMarkWidth: 8,
              itemMarkHeight: 8,
              markGap: 5,
              itemGap: 15,
              position: {
                vertical: 'top',
                horizontal: 'left',
              },
            },
          }}
        >
          <Box onClick={e => console.log(e)} />
        </LineChart>
      </Box>
    </CardBase>
  );
};
