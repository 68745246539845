import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';

export const useMembershipStatus = (schoolId: number, membershipId?: number) => {
  const membersResponse = useQuery<{ enrolments: number }, AxiosError>(
    ['membership-status', membershipId],
    async () => {
      const response = await axios.get(`/membership/status/${membershipId}`, { headers: { school: schoolId } });
      return response.data;
    },
    {
      enabled: !!membershipId,
    }
  );
  return membersResponse;
};
