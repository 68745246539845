import { Avatar, Box, Button, CircularProgress, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { School, useSchool } from 'hooks/useSchool';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import logo from '../../../assets/mm-logo.svg';
import { Status } from 'utils/types';
import { useSchools } from 'hooks/useSchools';
import { loadManifest } from '../../../setup-manifest';
import { Member, useMember } from 'hooks/useMember';
import { CreateSchoolModal } from 'components/modals/create-school-modal';
import { Account, useAccount } from 'hooks/useAccount';
import { useEventSource } from 'hooks/useEventSource';
import { useQueryClient } from 'react-query';
import { Enrolment } from 'hooks/useMember';
import { keyBy } from 'lodash';
import { useUserContext } from '../user-context';
import { SignupFlow } from 'pages/signup-flow';

export const SchoolContext = createContext({} as ContextItems);

export const useSchoolContext = () => useContext(SchoolContext);

type ContextItems = {
  school: School;
  cohorts: string[];
  member: Member;
  subjects: number[];
  account: Account;
  eventSource: EventSource;
};

export const Provider = ({ school, children }: { school: School; children: JSX.Element }) => {
  const queryClient = useQueryClient();
  const { data: account, isLoading: accountLoading } = useAccount(school.id);
  const { data: member, isLoading: memberLoading } = useMember(school.id);
  const eventSource = useEventSource(`school-${school.id}`);
  const cohorts = member?.cohorts.map(i => i.name) || [];
  const subjects = useMemo(
    () =>
      member?.enrolments
        .filter(i => i.status === Status.ACTIVE)
        .reduce((a, i) => a.concat(i.membership.subjects.map(s => s.id)), [] as number[]) || [],
    [member]
  );

  if (!member || !account || accountLoading || memberLoading) {
    return (
      <Box display={'flex'} width={1}>
        <CircularProgress size={50} sx={{ m: 'auto' }} />
      </Box>
    );
  }

  const updatedEnrolments = (mem: Member, enrolment: Enrolment) => {
    if (enrolment.userId === mem.userId) {
      const map = keyBy([...mem.enrolments, enrolment], e => `${e.userId}-${e.membershipId}`);
      return Object.values(map);
    }
    return mem.enrolments;
  };

  eventSource.onmessage = m => {
    const enrolment = JSON.parse(m.data)?.enrolment as Enrolment;
    queryClient.setQueriesData<Member | undefined>(['member', school.id], mem => {
      if (mem && enrolment) {
        return {
          ...mem,
          enrolments: updatedEnrolments(mem, enrolment),
          children: mem.children.map(c => ({
            ...c,
            enrolments: updatedEnrolments(c, enrolment),
          })),
        };
      }
    });
  };

  return (
    <SchoolContext.Provider value={{ school, cohorts, member, subjects, account, eventSource }}>
      <SignupFlow>{children}</SignupFlow>
    </SchoolContext.Provider>
  );
};

export const SchoolContextProvider = ({ schoolId, children }: { schoolId?: string; children: JSX.Element }) => {
  const { small } = useCustomTheme();
  const { logout } = useUserContext();
  const [createSchoolModal, setCreateSchoolModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { data: schools = [] } = useSchools(searchTerm);
  const { data: school, isLoading: schoolLoading } = useSchool(schoolId);

  useEffect(() => {
    if (!schoolLoading) {
      if (school) {
        loadManifest(school);
      } else {
        loadManifest();
      }
    }
  }, [school, schoolLoading]);

  if (schoolLoading) {
    return (
      <Box display={'flex'} width={1}>
        <CircularProgress size={50} sx={{ m: 'auto' }} />
      </Box>
    );
  }

  if (!school) {
    return (
      <>
        <CreateSchoolModal open={createSchoolModal} onClose={() => setCreateSchoolModal(false)} />
        <Grid container height={'100vh'} direction={'column'} justifyContent={'center'} alignContent={'center'}>
          <Box width={250} mb={3} alignSelf={'center'} component={'img'} src={logo} />
          <TextField
            label={'search school'}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            sx={{ width: small ? '90vw' : '250px' }}
            autoComplete="off"
          />
          {searchTerm && (
            <Box>
              {schools?.map(i => (
                <MenuItem
                  key={i.id}
                  sx={{ borderRadius: 50, pl: 1, height: 48, my: 1 }}
                  onClick={() => (location.href = `${location.origin}/${i.uuid}`)}
                >
                  <Avatar src={i.logo} sizes="30px" />
                  <Typography ml={2} variant="h2">
                    {i.name}
                  </Typography>
                </MenuItem>
              ))}
            </Box>
          )}
          <Box position={'absolute'} bottom={0} display={'flex'} width={1} justifyContent={'space-between'}>
            <Box m={8}>
              <Button variant="text" onClick={() => setCreateSchoolModal(true)}>
                Add school
              </Button>
            </Box>
            <Box m={8}>
              <Button variant="text" onClick={() => logout()}>
                Logout
              </Button>
            </Box>
          </Box>
        </Grid>
      </>
    );
  }

  return <Provider school={school}>{children}</Provider>;
};
