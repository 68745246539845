import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { useSession } from './useSession';

export type Account = { id: string; charges_enabled: boolean; payouts_enabled: boolean };

export const useAccount = (schoolId?: number): UseQueryResult<Account> => {
  const { session } = useSession();

  const accountResponse = useQuery(
    ['payment', session, schoolId],
    async () => {
      const response = await axios.get('/payment/account', { headers: { ...(schoolId && { school: schoolId }) } });
      return response.data;
    },
    {
      enabled: session && !!schoolId,
    }
  );
  return accountResponse;
};
