import { Avatar, Box, Card, CardActionArea, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useKioskContext } from 'components/context/kiosk-context';
import { useKioskSessions } from 'hooks/useKioskSessions';
import { dayName, friendlyDate, friendlyTime, iso } from 'utils/time';
import { Search } from './search.tsx';
import { useEffect, useMemo, useState } from 'react';
import { Schedule } from 'components/home/timetable/index.js';
import { DateTime } from 'luxon';

export const Kiosk = () => {
  const { kiosk, session, setSession } = useKioskContext();
  const { sessions } = useKioskSessions(kiosk);
  const [now, setNow] = useState(DateTime.now());
  const [skipInterval, setSkipInterval] = useState(false);
  const margin = 1;
  const startTime = now.minus({ hours: margin }).toISO();

  const day = DateTime.now().toISODate();

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(DateTime.now());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const schedule: Schedule = useMemo(
    () => ({
      date: day,
      sessions: sessions.filter(
        c =>
          iso(day).weekNumber % c.frequency === c.week &&
          dayName(c.dateTime) === dayName(day) &&
          iso(c.dateTime).hour >= iso(now.toISO()).hour - margin &&
          iso(c.dateTime).hour <= iso(now.toISO()).hour + margin &&
          (c?.endDate ? iso(c.endDate).toMillis() >= iso(startTime).toMillis() : true)
      ),
    }),
    [sessions, day, startTime, now]
  );

  useEffect(() => {
    if (!skipInterval || schedule.sessions.length === 1) {
      schedule.sessions.length === 1 ? setSession(schedule.sessions[0]) : setSession(undefined);
    }
  }, [schedule, session]);

  useEffect(() => {
    if (skipInterval) {
      setSkipInterval(false);
    }
  }, [schedule]);

  return (
    <>
      <Box display={'flex'} p={4}>
        <Avatar src={kiosk.school.logo} sx={{ width: '60px', height: '60px', boxShadow: `1px 2px 2px ${grey[500]}` }} />
      </Box>
      <Box height={'90%'} px={4}>
        {session ? (
          <Search session={session} onChange={() => setSkipInterval(true)} />
        ) : (
          <>
            <Typography variant="h1" color={grey[800]}>
              {friendlyDate(schedule.date)}
            </Typography>
            <Box height={'100%'}>
              <Typography variant="h2" color={grey[800]} mt={12}>
                {!schedule.sessions.length ? 'no current classes' : 'select class'}
              </Typography>
              <Box mt={8}>
                {schedule.sessions.map(i => (
                  <Card
                    key={i.id}
                    sx={{
                      m: 'auto',
                      maxWidth: 700,
                      mb: 4,
                      bgcolor: i.subject[0].color,
                      borderRadius: 50,
                    }}
                  >
                    <CardActionArea
                      onClick={() => {
                        setSession(i);
                        setSkipInterval(true);
                      }}
                      sx={{ display: 'flex', px: 6, height: 80 }}
                    >
                      <Box my={'auto'} mr={2}>
                        <Typography color={'white'} variant="h3" textAlign={'center'}>
                          {friendlyTime(i.dateTime).slice(0, -2)}
                        </Typography>
                        <Typography color={'white'} variant="h3" textAlign={'center'}>
                          {friendlyTime(i.dateTime).slice(-2).toUpperCase()}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          m: 'auto',
                          overflow: 'hidden',
                          maxHeight: 60,
                          display: '-webkit-box',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                        }}
                        key={i.id}
                      >
                        <Typography variant="h1" color={'white'}>
                          {i.subject.map(s => s.name).join(', ')}
                        </Typography>
                      </Box>
                    </CardActionArea>
                  </Card>
                ))}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
