import { Box, Card, Modal, SxProps, Typography } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { useCustomTheme } from 'hooks/useCustomTheme';

type Props = { title: string; open: boolean; children: JSX.Element; onClose?: () => void; sx?: SxProps };

export const ModalBase = ({ title, open, children, onClose, sx }: Props) => {
  const { cardWidth } = useCustomTheme();

  return (
    <Modal
      open={open}
      onClose={(e, r) => {
        if (r === 'backdropClick') onClose?.();
      }}
      sx={{ backdropFilter: 'rgba(0, 0, 0, 0.2)', display: 'flex' }}
    >
      <Card
        sx={{
          m: 'auto',
          p: 4,
          boxShadow: `0.5px 1px 1px ${grey[400]}`,
          width: cardWidth,
          display: 'flex',
          ...sx,
        }}
      >
        <Box width={1}>
          <Typography variant="h3">{title}</Typography>
          {children}
        </Box>
      </Card>
    </Modal>
  );
};
