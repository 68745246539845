import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

export type CreateCohortDto = {
  id?: number;
  name: string;
  colors?: string;
};
export type Cohort = { id: number; name: string; schoolId: number; colors: string };

export const useCohorts = (schoolId: number): UseQueryResult<Cohort[]> => {
  const queryResponse = useQuery<any, AxiosError>(
    ['cohort', schoolId],
    async () => {
      const response = await axios.get('/cohort', { headers: { school: schoolId } });
      return response.data;
    },
    {
      enabled: !!schoolId,
    }
  );

  return queryResponse;
};
