import { Box, Typography, Button } from '@mui/material';
import { CardBase } from 'components/shared/card-base';
import ErrorIcon from '@mui/icons-material/Error';
import { OverdueStatus } from 'utils/types';
import { useMutate } from 'hooks/useMutate';
import { useEffect } from 'react';
import { useSchoolContext } from '.';

export const PaymentAlert = () => {
  const { member } = useSchoolContext();
  const overdueEnrolment = member.enrolments.find(i => OverdueStatus.includes(i.status));

  const { create: checkout, response: checkoutUrl } = useMutate<string>('/payment/checkout');

  useEffect(() => {
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  }, [checkoutUrl]);

  return (
    <>
      {overdueEnrolment && (
        <CardBase
          sx={{
            position: 'absolute',
            display: 'flex',
            zIndex: 999,
            mb: 16,
            bottom: 0,
            left: '50%',
            translate: ['-50%', '-50%'],
            outline: 'red solid 2px',
          }}
        >
          <Box sx={{ height: 40, p: 2, display: 'flex', width: 1 }}>
            <ErrorIcon sx={{ color: 'error.main' }} />
            <Typography m="auto" variant="h3">
              Update payment method
            </Typography>
            <Button
              variant="contained"
              onClick={() =>
                checkout({
                  invoiceId: overdueEnrolment.invoiceId,
                })
              }
            >
              Pay now
            </Button>
          </Box>
        </CardBase>
      )}
    </>
  );
};
