import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Kiosk } from './useKiosk';

export type KioskUser = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
};

export const useKioskUsers = (kiosk: Kiosk) => {
  const { uuid, token } = kiosk;
  const { data, isLoading } = useQuery<KioskUser[], AxiosError>(
    ['kiosk-users', uuid, token],
    async () => {
      const response = await axios.get(`/kiosk/users`, { headers: { uuid, token } });
      return response.data;
    },
    {
      enabled: !!(token && uuid),
    }
  );

  return { users: data, isLoading };
};
