import {
  Card,
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  ButtonBase,
  IconButton,
  InputAdornment,
  Checkbox,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutate } from '../../../hooks/useMutate';
import { CreateSubject, Subject, useSubject } from '../../../hooks/useSubject';
import { useSchoolContext } from '../../context/school-context';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { CustomModal } from 'components/shared/custom-modal';
import EditIcon from '@mui/icons-material/Edit';
import { ColorPicker } from '../color-picker';

type Props = { subject?: Subject; open: boolean; close: (args?: number) => void };

export const SubjectModal = ({ subject, open, close }: Props) => {
  const { school } = useSchoolContext();
  const { subjectsResponse } = useSubject(school.id);
  const { create, destroy } = useMutate<CreateSubject>('subject', { invalidate: ['subject'] });
  const { small } = useCustomTheme();
  const [color, setColor] = useState(subject?.color || '');
  const [colorPicker, setColorPicker] = useState(false);
  const [name, setName] = useState(subject?.name || '');
  const [maxAge, setMaxAge] = useState(subject?.maxAge || null);
  const [minAge, setMinAge] = useState(subject?.minAge || null);
  const [restricted, setRestricted] = useState<boolean>(!!subject?.minAge || !!subject?.maxAge);
  const [description, setDescription] = useState(subject?.description || '');
  const call = useRef<boolean>();

  const invalidAgeRange = !!maxAge && maxAge < (minAge || 0);

  const existingSubject = useMemo(
    () => subjectsResponse.data?.find(s => s.name === name),
    [subjectsResponse.data, name]
  );

  const disabled = useMemo(() => (!!existingSubject && !subject?.id) || !name, [name, existingSubject]);

  useEffect(() => {
    if (call.current) close(existingSubject?.id || 0);
  }, [subjectsResponse.data]);

  const handleCreate = () => {
    create({
      id: subject?.id,
      schoolId: school.id,
      name,
      description,
      color,
      ...(restricted && { minAge, maxAge }),
    });
    call.current = true;
  };

  return (
    <CustomModal open={open} onClose={() => close(0)}>
      <Card
        sx={{
          width: small ? '90vw' : '450px',
          m: 'auto',
          p: 6,
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography mb={2} variant="h3">
            {subject ? 'Update subject' : 'New subject'}
          </Typography>
        </Box>
        <Grid item xs={12}>
          <TextField
            error={!subject?.id && !!existingSubject}
            helperText={!subject?.id && !!existingSubject ? 'subject must be unique' : ''}
            label={'subject name'}
            value={name}
            onChange={e => setName(e.target.value)}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            rows={5}
            multiline
            variant="outlined"
            label={'description'}
            value={description}
            onChange={e => setDescription(e.target.value)}
          ></TextField>
        </Grid>
        <Box mt={2} display={'flex'}>
          <Checkbox checked={restricted} title="limited?" onChange={e => setRestricted(e.currentTarget.checked)} />
          <Typography ml={2} variant="h5" my="auto">
            Age restriction
          </Typography>
        </Box>
        {restricted && (
          <Box display={'flex'}>
            <Grid item xs={12} mr={2}>
              <TextField
                error={invalidAgeRange}
                helperText={invalidAgeRange ? 'invalid minimum age' : ''}
                label={'minimum age'}
                value={minAge}
                onChange={e => setMinAge(Number(e.target.value))}
              />
            </Grid>
            <Grid item xs={12} ml={2}>
              <TextField label={'maximum age'} value={maxAge} onChange={e => setMaxAge(Number(e.target.value))} />
            </Grid>
          </Box>
        )}
        <Grid mb={2} item xs={12}>
          <TextField
            label={`Display color`}
            value={color}
            onChange={e => setColor(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setColorPicker(true)}>
                    <EditIcon />
                  </IconButton>
                  <ColorPicker
                    open={colorPicker}
                    color={color}
                    setColor={setColor}
                    onClose={() => setColorPicker(false)}
                  />
                  <ButtonBase onClick={() => setColorPicker(true)}>
                    <Box sx={{ marginRight: -2, width: 140, height: 25, borderRadius: 3, backgroundColor: color }} />
                  </ButtonBase>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid mt={4} item xs={12} display={'flex'} justifyContent="space-between">
          <Box>
            {subject && (
              <Button
                variant="outlined"
                sx={{ justifySelf: 'start' }}
                onClick={() => {
                  destroy(subject.id);
                  close();
                }}
              >
                Delete
              </Button>
            )}
          </Box>
          <Button disabled={disabled} onClick={handleCreate} variant="contained">
            {subject?.id ? 'Save' : 'Create'}
          </Button>
        </Grid>
      </Card>
    </CustomModal>
  );
};
