import { Grid, Box, Typography, Chip } from '@mui/material';
import { Membership } from 'hooks/useMemership';

type Props = {
  membership: Membership;
};

export const Subjects = ({ membership }: Props) => {
  return (
    <Grid container flexGrow={1} direction={'column'} overflow={'scroll'}>
      <Box my={1} width={1}>
        <Box>
          <Typography variant="h5">Subjects</Typography>
        </Box>
        <Box>
          {membership.subjects
            ?.sort((a, b) => a.id - b.id)
            .map(i => (
              <Chip
                key={i.id}
                size="small"
                label={i.name}
                sx={{
                  color: 'white',
                  m: 0.5,
                  fontWeight: 700,
                  stroke: 'red',
                  backgroundColor: i.color,
                  maxWidth: '98%',
                }}
              />
            ))}
        </Box>
      </Box>
    </Grid>
  );
};
