import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { School } from './useSchool';

export type Kiosk = {
  uuid: string;
  token: string;
  schoolId: number;
  school: School;
};

export const useKiosk = (token: string, uuid = '', agent = 'unknown') => {
  const { data, isLoading } = useQuery<Kiosk, AxiosError>(
    ['kiosk', uuid, token],
    async () => {
      const response = await axios.get(`/auth/kiosk`, { headers: { uuid, token, agent } });
      return response.data;
    },
    {
      enabled: !!(token && uuid),
    }
  );

  return { kiosk: data, isLoading };
};
