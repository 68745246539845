import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';

export type MemberTracking = {
  id: number;
  schoolId: number;
  date: string;
  prospect: number;
  inactive: number;
  active: number;
};

export const useUserMemberTracking = (schoolId: number) => {
  const membersResponse = useQuery<MemberTracking[], AxiosError>(['member-tracking'], async () => {
    const response = await axios.get('/admin/member-tracking', { headers: { school: schoolId } });
    return response.data;
  });

  return membersResponse;
};
