import { Card, CardActionArea, Box, Typography, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DisplayImage } from '../display-image';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { Enrolment, Member } from 'hooks/useMember';
import { StatusColor, statusText } from 'utils/types';
import { Status } from '../status';
import { Membership } from 'hooks/useMemership';

type Props = {
  member: Member;
  enrolment: Enrolment;
  membership: Membership;
  onClick: () => void;
  sx?: SxProps;
};

export const EnrolmentButton = ({ enrolment, membership, member: { user }, onClick, sx = {} }: Props) => {
  const { cardWidth } = useCustomTheme();

  return (
    <Card
      sx={{
        width: cardWidth,
        boxShadow: `0.5px 1px 3px ${grey[400]}`,
        height: 104,
        ...sx,
      }}
    >
      <CardActionArea onClick={onClick} sx={{ p: 3, height: 104 }}>
        <Box height={1} sx={{ justifyContent: 'space-between' }}>
          <Typography mb={2} variant="h3" noWrap>
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Box display={'flex'} width={1} overflow={'hidden'}>
            <DisplayImage firstName={user.firstName} picture={user.picture} size={40} sx={{ my: 'auto' }} />
            <Box my={'auto'} width={'85%'} ml={3}>
              <Box mt={1}>
                <Typography variant="h4">{membership.name}</Typography>
              </Box>
              <Box display={'flex'}>
                <Status severity={StatusColor[enrolment.status]} height={22} sx={{ pl: 0, mt: 2, pr: 2 }}>
                  <Box mt={'-7px'}>{statusText[enrolment.status]}</Box>
                </Status>
                <Typography variant="h5" sx={{ ml: 2, mt: 2.5 }}>
                  {`$${(enrolment.price / 100).toFixed(2)} ${enrolment.billingCycle || ''}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};
