import { Box, TextField } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { contrast } from 'Theme';

type Props = {
  label: string;
  value: string;
  onChange: (v: string) => void;
};

export const InputField = ({ label, value, onChange }: Props) => {
  const { cardWidth } = useCustomTheme();
  const { school } = useSchoolContext();

  return (
    <Box>
      <TextField
        value={value}
        onChange={v => onChange(v.target.value)}
        variant="standard"
        label={label}
        autoComplete="off"
        sx={{
          width: cardWidth,
          '&& .MuiInputBase-root::before': {
            borderBottom: `2px solid ${contrast(school.priColor)}`,
          },
          '&& .MuiInputBase-root:hover::before': {
            borderBottom: `2px solid ${contrast(school.priColor)}`,
          },
        }}
        slotProps={{
          input: {
            style: {
              backgroundColor: 'transparent',
              color: contrast(school.priColor),
            },
          },
          inputLabel: { sx: { color: contrast(school.priColor) } },
        }}
      />
    </Box>
  );
};
