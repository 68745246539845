import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { useCustomTheme } from 'hooks/useCustomTheme';
import EditIcon from '@mui/icons-material/Edit';
import { useMutate } from 'hooks/useMutate';
import { ImageUploadModal } from 'components/modals/image-upload-modal';
import { useMemo, useState } from 'react';
import { SetupKiosk } from 'components/modals/setup-kiosk-modal';
import logo from '../../../../assets/mm-logo.svg';
import { ColorPicker } from 'components/modals/color-picker';
import { useSchoolMembers } from 'hooks/admin/useSchoolMembers';
import { UpdateMemberDto } from 'utils/types';
import { User } from 'hooks/useUser';
import { useUserContext } from 'components/context/user-context';
import { Status } from 'components/shared/status';
import { OWNERS } from '../../constants';
import { Toast } from 'components/shared/toast';

export const SchoolSettings = () => {
  const { school, account } = useSchoolContext();
  const { user } = useUserContext();
  const membersResponse = useSchoolMembers(school.id);
  const { create: update } = useMutate<UpdateMemberDto>('admin/member', {
    invalidate: ['members'],
  });
  const { cardWidth } = useCustomTheme();
  const [schoolName, setSchoolName] = useState(school.name);
  const [schoolEmail, setSchoolEmail] = useState(school.email);
  const [priColor, setPriColor] = useState(school.priColor);
  const [secColor, setSecColor] = useState(school.secColor);
  const [terColor, setTerColor] = useState(school.terColor);
  const [colorPickers, setColorPickers] = useState({ pri: false, sec: false, ter: false });
  const [setupKiosk, setSetupKiosk] = useState(false);

  const [locationId, setLocationId] = useState('');
  const [key, setKey] = useState('');
  const [crmAcive, setCrmActive] = useState('');

  const { create: uploadLogo } = useMutate('/school/update-logo', { onSuccess: () => window.location.reload() });
  const { create } = useMutate('/school/update', { onSuccess: () => window.location.reload() });
  const { create: updateCrm } = useMutate('/admin/crm/location', {
    onSuccess: resp => setCrmActive(resp as string),
  });
  const { create: syncContacts } = useMutate('/admin/crm/contacts', {
    onSuccess: resp => setCrmActive(resp as string),
  });
  const { create: requestLink } = useMutate('payment/link', {
    onSuccess: response => (location.href = response as string),
    headers: {
      href: location.href,
    },
  });
  const members = useMemo(
    () => membersResponse?.data?.filter(m => m.user.email && m.userId !== user.id) || [],
    [membersResponse.data]
  );

  const accountValid = account.charges_enabled && account.payouts_enabled;

  const handleSubmit = () => {
    const data = {
      name: schoolName,
      email: schoolEmail,
      priColor: priColor,
      secColor: secColor,
      terColor: terColor,
    };
    create(data);
  };

  const handleUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('logo', file);
    uploadLogo(formData);
  };

  const setPickers = (picker?: 'pri' | 'sec' | 'ter') => {
    if (!picker) setColorPickers({ pri: false, sec: false, ter: false });
    else setColorPickers({ pri: picker === 'pri', sec: picker === 'sec', ter: picker === 'ter' });
  };

  const handleSelect = (memberUser: User, isAdmin: boolean) => {
    update({ userId: memberUser.id, schoolId: school.id, isAdmin, user: memberUser });
  };

  return (
    <>
      <SetupKiosk open={setupKiosk} onClose={() => setSetupKiosk(false)} />
      <Grid container justifyContent={'center'} alignContent={'center'} mt={10} mb={30}>
        <Box sx={{ my: 3, width: cardWidth }}>
          <Grid container mb={3} justifyContent={'center'}>
            <CardMedia style={{ width: 100, borderRadius: '50%' }} component={'img'} src={school.logo || logo} />
            <Box mt={6} ml={6} width={100}>
              <Box display={'flex'} width={'100%'}>
                <Typography m={'auto'} variant="h3">
                  School logo
                </Typography>
              </Box>
              <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                <ImageUploadModal logoColor={school.priColor} onSuccess={handleUpload} round={false} />
              </Box>
            </Box>
          </Grid>
          <TextField
            label={`Business name`}
            value={schoolName}
            onChange={e => setSchoolName(e.target.value)}
            onClick={() => setPickers()}
          />
          <TextField
            type="email"
            label={`Business email`}
            value={schoolEmail}
            onChange={e => setSchoolEmail(e.target.value)}
            onClick={() => setPickers()}
          />
          <Box sx={{ my: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Status severity={accountValid ? 'success' : 'warning'}>
              <Box mt={'-1px'}>{account.id}</Box>
            </Status>
            <Button variant="contained" onClick={() => requestLink({})}>
              Configure Stripe
            </Button>
          </Box>
          <TextField
            label={`Brand color`}
            value={priColor}
            onChange={e => setPriColor(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setPickers('pri')}>
                    <EditIcon />
                  </IconButton>
                  <ColorPicker open={colorPickers.pri} color={priColor} setColor={setPriColor} onClose={setPickers} />
                  <ButtonBase onClick={() => setPickers('pri')}>
                    <Box sx={{ marginRight: -2, width: 140, height: 25, borderRadius: 3, backgroundColor: priColor }} />
                  </ButtonBase>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label={`Secondary color`}
            value={secColor}
            onChange={e => setSecColor(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setPickers('sec')}>
                    <EditIcon />
                  </IconButton>
                  <ColorPicker open={colorPickers.sec} color={secColor} setColor={setSecColor} onClose={setPickers} />
                  <ButtonBase onClick={() => setPickers('sec')}>
                    <Box sx={{ marginRight: -2, width: 140, height: 25, borderRadius: 3, backgroundColor: secColor }} />
                  </ButtonBase>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label={`Tertiary color`}
            value={terColor}
            onChange={e => setTerColor(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setPickers('ter')}>
                    <EditIcon />
                  </IconButton>
                  <ColorPicker open={colorPickers.ter} color={terColor} setColor={setTerColor} onClose={setPickers} />
                  <ButtonBase onClick={() => setPickers('ter')}>
                    <Box sx={{ marginRight: -2, width: 140, height: 25, borderRadius: 3, backgroundColor: terColor }} />
                  </ButtonBase>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            select
            label={'Admins'}
            SelectProps={{
              multiple: true,
              value: members.filter(m => m.isAdmin).map(m => m.userId),
              MenuProps: {
                sx: { maxHeight: 190 },
              },
            }}
          >
            {members.map(m => (
              <MenuItem onClick={() => handleSelect(m.user, !m.isAdmin)} key={m.userId} value={m.userId}>
                {`${m.user.firstName} ${m.user.lastName}`}
              </MenuItem>
            ))}
          </TextField>
          <Box display={'flex'} justifyContent={'space-between'} mt={4}>
            <Button variant="contained" onClick={() => setSetupKiosk(true)}>
              Configure kiosk
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>
          {OWNERS.includes(user.email) && (
            <Box>
              <Typography mt={12} mb={1} variant="h3">
                Hidden settings
              </Typography>
              <TextField
                type="text"
                label={`Highlevel location id`}
                value={locationId}
                onChange={e => setLocationId(e.target.value)}
                onClick={() => setPickers()}
              />
              <TextField
                type="text"
                label={`Highlevel api key`}
                value={key}
                onChange={e => setKey(e.target.value)}
                onClick={() => setPickers()}
              />
              <Box display={'flex'} justifyContent={'end'} mt={4}>
                <Button
                  disabled={!locationId || !key}
                  variant="contained"
                  onClick={() => {
                    updateCrm({ locationId, key });
                  }}
                >
                  Save
                </Button>
              </Box>
              <Box display={'flex'} justifyContent={'end'} mt={3}>
                <Button
                  variant="contained"
                  onClick={() => {
                    syncContacts({});
                  }}
                >
                  Sync members
                </Button>
              </Box>
              <Toast open={!!crmAcive} onClose={() => setCrmActive('')} message={crmAcive} />
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};
