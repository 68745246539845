import { Card, CardActionArea, Box, Typography, SxProps } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';
import { THEME } from 'Theme';

type Props = {
  label: string;
  onClick: () => void;
  sx?: SxProps;
};

export const AddButton = ({ label, onClick, sx = {} }: Props) => {
  const { school } = useSchoolContext();

  return (
    <Card
      sx={{
        boxShadow: `0.5px 1px 3px ${grey[400]}`,
        height: 50,
        ...sx,
      }}
    >
      <CardActionArea onClick={onClick} sx={{ px: 1, height: 50 }}>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Typography m={'auto'} pl={6} variant="h4">
            {label}
          </Typography>
          <Box borderRadius={50} bgcolor={school.priColor} sx={{ display: 'flex' }}>
            <AddIcon sx={{ m: 2, color: THEME.palette.getContrastText(school.priColor) }} />
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};
