import axios from 'axios';
import { useMutation } from 'react-query';
import { Kiosk } from './useKiosk';

type Attendance = { userId: number; classId: number; date: string };

export const useKioskAttendance = (kiosk: Kiosk, onSuccess: () => void, onError: () => void) => {
  const { uuid, token } = kiosk;

  const { mutate: create } = useMutation(
    (data: Attendance) => axios.post(`/kiosk/attendance`, data, { headers: { uuid, token } }),
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  return { create };
};
