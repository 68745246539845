import { Card, Grid, TextField, Typography, Button, Checkbox } from '@mui/material';
import { useMemo, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { iso } from '../../../utils/time';
import { DateTime } from 'luxon';
import { useMutate } from 'hooks/useMutate';
import { Box } from '@mui/system';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { CustomModal } from 'components/shared/custom-modal';
import { LocationsSelect } from 'components/shared/locations-select';
import { Closure, CreateClosure } from 'hooks/useClosure';
import { SubjectSelect } from 'components/shared/subject-select';

type Props = {
  open: boolean;
  close: () => void;
  closure?: Closure;
};

export const ClosureModal = ({ open, close, closure }: Props) => {
  const { create, destroy } = useMutate<CreateClosure>('class/closure', { invalidate: ['closure'] });
  const { small } = useCustomTheme();
  const date = DateTime.now().toISODate();

  const [locations, setLocations] = useState(closure?.locations.map(i => i.id) || []);
  const [subjects, setSubjects] = useState(closure?.subjects.map(i => i.id) || []);
  const [description, setDescription] = useState<string>(closure?.description || '');
  const [startDate, setStartDate] = useState<DateTime>(iso(closure?.startDate || date));
  const [endDate, setEndDate] = useState<DateTime>(iso(closure?.endDate || date));
  const [repeating, setRepeating] = useState<boolean>(closure?.repeating || false);

  const disabled = useMemo(() => !description || !startDate || !endDate, [description, startDate, endDate]);

  const handleCreate = () => {
    const classDto: CreateClosure = {
      id: closure ? closure.id : undefined,
      locations: locations.map(s => Number(s)),
      subjects: subjects.map(s => Number(s)),
      description: description,
      startDate: startDate.toUTC().toString(),
      endDate: (endDate < startDate ? startDate : endDate).endOf('day').toUTC().toString(),
      repeating: repeating,
    };
    create(classDto);
    close();
  };

  return (
    <CustomModal open={open} onClose={close}>
      <Card
        sx={{
          width: small ? '94vw' : '500px',
          m: 'auto',
          p: 6,
          justifyItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography ml={2} variant="h3">
            {closure ? 'Edit closure' : 'Add closure'}
          </Typography>
        </Box>
        <LocationsSelect placeholder="All locations" locations={locations} setLocations={setLocations} create={false} />
        <SubjectSelect placeholder="All subjects" subjects={subjects} setSubjects={setSubjects} create={false} />
        <TextField
          label={'description'}
          type={'description'}
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <Box display={'flex'}>
          <DatePicker
            label={'from'}
            format={repeating ? 'dd/MM' : 'dd/MM/yyyy'}
            onChange={d => {
              setStartDate(d as DateTime);
            }}
            value={startDate}
            sx={{ mr: 3 }}
          />
          <DatePicker
            label={'to'}
            format={repeating ? 'dd/MM' : 'dd/MM/yyyy'}
            minDate={startDate}
            onChange={d => {
              setEndDate(d as DateTime);
            }}
            value={endDate < startDate ? startDate : endDate}
            sx={{ ml: 3 }}
          />
        </Box>
        <Box display={'flex'}>
          <Box display={'flex'} pt={2}>
            <Box>
              <Checkbox checked={repeating} onChange={v => setRepeating(v.currentTarget.checked)} />
            </Box>
            <Typography m={'auto'} pl={2}>
              Repeat annually
            </Typography>
          </Box>
        </Box>
        <Grid mt={6} item xs={12} display={'flex'} justifyContent={'space-between'}>
          <Box>
            {closure && (
              <Button
                variant="outlined"
                sx={{ justifySelf: 'start' }}
                onClick={() => {
                  destroy(closure.id);
                  close();
                }}
              >
                Delete
              </Button>
            )}
          </Box>
          <Box>
            <Button
              disabled={disabled}
              variant="contained"
              onClick={() => {
                handleCreate();
              }}
            >
              {closure ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Grid>
      </Card>
    </CustomModal>
  );
};
