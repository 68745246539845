import { Box } from '@mui/system';
import { useSchoolContext } from 'components/context/school-context';
import { InputField } from './input-field';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { iso } from 'utils/time';
import { BirthdayPicker } from './date-picker';
import { grey } from '@mui/material/colors';
import { useMutate } from 'hooks/useMutate';
import { useUserContext } from 'components/context/user-context';
import { WaveBorder } from './wave-border';
import { Footer } from './footer';
import { Typography } from '@mui/material';
import { contrast } from 'Theme';

export const MemberDetails = () => {
  const { school } = useSchoolContext();
  const { user } = useUserContext();
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [phone, setPhone] = useState(user.phone || '');
  const [birthday, setBirthday] = useState<DateTime | null>(user.birthday ? iso(user.birthday) : null);

  const { update } = useMutate('user', {
    invalidate: ['user'],
  });

  const handleSubmit = () => {
    const data = {
      id: user.id,
      firstName,
      lastName,
      phone,
      birthday: birthday?.toISODate(),
      onboarding: { ...user.onboarding, user: true },
    };
    update(data);
  };

  return (
    <Box display={'flex'} width={1} justifyContent={'center'}>
      <Box width={1}>
        <Box sx={{ display: 'flex', textAlign: 'center' }} height={'14vh'} bgcolor={grey[100]}>
          <Box width={100} m={'auto'} component={'img'} src={school.logo} borderRadius={'50%'} />
        </Box>
        <WaveBorder color={school.priColor} />
        <Box
          flexDirection={'column'}
          display={'flex'}
          width={1}
          textAlign={'center'}
          height={'80vh'}
          pb={20}
          bgcolor={school.priColor}
          color={contrast(school.priColor)}
          overflow={'auto'}
        >
          <Box m={'auto'}>
            <Typography variant="h1">Your profile</Typography>
            <InputField label={'First name'} value={firstName} onChange={v => setFirstName(v)} />
            <InputField label={'Last name'} value={lastName} onChange={v => setLastName(v)} />
            <InputField label={'Phone'} value={phone} onChange={v => setPhone(v)} />
            <BirthdayPicker value={birthday} onChange={v => setBirthday(v)} />
          </Box>
        </Box>
        <Footer
          onClick={() => {
            handleSubmit();
          }}
          buttonText={'Next >'}
          disabled={!firstName || !lastName || !phone || !birthday}
          stage={2}
        />
      </Box>
    </Box>
  );
};
