import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { School } from './useSchool';

export const useSchools = (searchTerm = '') => {
  const schoolsResponse = useQuery<School[], AxiosError>(
    ['school', searchTerm],
    async () => {
      const response = await axios.get(`/school`);
      return response.data;
    },
    {}
  );

  const minLength = searchTerm.length > 2;

  if (schoolsResponse.data) {
    const data = minLength
      ? schoolsResponse.data.filter(i => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
      : [];
    return { ...schoolsResponse, data };
  }

  return { ...schoolsResponse };
};
