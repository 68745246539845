import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';

export type Transaction = {
  amount: number;
  created: number;
  reporting_category: string;
};

export const useTransactions = (schoolId: number) => {
  const transactions = useQuery<Transaction[], AxiosError>(
    ['transactions', schoolId],
    async () => {
      const response = await axios.get('/payment/transactions', { headers: { school: schoolId } });
      return response.data;
    },
    {}
  );
  return transactions;
};
