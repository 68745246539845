import { Typography, Box } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { contrast } from 'Theme';
import { WaveBorder } from './wave-border';
import { Footer } from './footer';

export const Greeting = ({ onClick }: { onClick: () => void }) => {
  const { school } = useSchoolContext();

  return (
    <Box display={'flex'} width={1} justifyContent={'center'} color={contrast(school.priColor)}>
      <Box width={1}>
        <Box width={1}>
          <Box sx={{ textAlign: 'center' }} py={'7vh'} height={'45vh'}>
            <Box width={250} component={'img'} src={school.logo} borderRadius={'50%'} />
          </Box>
          <WaveBorder color={school.priColor} />
          <Box
            flexDirection={'column'}
            display={'flex'}
            justifyContent={'space-between'}
            pt={20}
            pb={50}
            width={1}
            textAlign={'center'}
            bgcolor={school.priColor}
            height={'55vh'}
            bottom={0}
          >
            <Box>
              <Typography variant="h2">Welcome to</Typography>
              <Typography mt={3} variant="h1">
                {school.name}
              </Typography>
            </Box>
            <Box mt={'2vh'}>
              <Typography variant="h2">LET'S GET STARTED</Typography>
            </Box>
          </Box>
          <Footer onClick={onClick} buttonText={'Begin >'} stage={1} />
        </Box>
      </Box>
    </Box>
  );
};
