import { Card, Grid, TextField, Typography, Button, MenuItem, InputAdornment } from '@mui/material';
import { useMemo, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useMutate } from 'hooks/useMutate';
import { Box } from '@mui/system';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { CustomModal } from 'components/shared/custom-modal';
import { Coupon, CreateCouponDto, Duration } from 'hooks/useCoupons';

type Props = {
  open: boolean;
  onClose: () => void;
  coupon?: Coupon;
};

type DiscountType = 'percent' | 'value';

export const CouponModal = ({ open, onClose, coupon }: Props) => {
  const { create, destroy } = useMutate<Coupon>('coupon', { invalidate: ['coupon'] });
  const { small } = useCustomTheme();

  const [name, setName] = useState<string>(coupon?.name || '');
  const [amountOff, setAmountOff] = useState<number>(coupon?.amount_off || 0);
  const [percentOff, setPercentOff] = useState<number>(coupon?.percent_off || 0);
  const [discountType, setDiscountType] = useState<DiscountType>(coupon?.percent_off ? 'percent' : 'value');
  const [duration, setDuration] = useState<Duration>(coupon?.duration || Duration.FOREVER);
  const [durationInMonths, setDurationInMonths] = useState<number>(coupon?.duration_in_months || 1);
  const [maxRedemptions, setMaxRedemptions] = useState<number>(coupon?.max_redemptions || 0);
  const [redeemBy, setRedeemBy] = useState<DateTime | null>(
    coupon?.redeem_by ? DateTime.fromSeconds(coupon.redeem_by) : null
  );

  const disabled = useMemo(
    () => (!name || discountType === 'percent' ? !percentOff : !amountOff),
    [name, discountType, percentOff, amountOff]
  );

  const handleCreate = () => {
    const data: CreateCouponDto = {
      ...(coupon && { id: coupon.id }),
      name,
      ...(discountType === 'value' && { amount_off: amountOff }),
      ...(discountType === 'percent' && { percent_off: percentOff }),
      duration,
      ...(duration === Duration.REPEATING && { duration_in_months: durationInMonths }),
      ...(maxRedemptions && { max_redemptions: maxRedemptions }),
      ...(redeemBy && { redeem_by: Math.round(redeemBy.toSeconds()) }),
      currency: 'aud',
    };
    create(data);
    onClose();
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <Card
        sx={{
          width: small ? '94vw' : '500px',
          m: 'auto',
          p: 6,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography ml={2} variant="h3">
            {coupon ? 'Edit coupon' : 'Add coupon'}
          </Typography>
        </Box>
        <TextField label={'name'} type={'name'} value={name} onChange={e => setName(e.target.value)} />
        <Box display={'flex'}>
          <TextField
            select
            label={'discount type'}
            value={discountType}
            onChange={i => setDiscountType(i.target.value as DiscountType)}
            sx={{ mr: 3 }}
          >
            <MenuItem value={'percent'}>{'percentage discount'}</MenuItem>
            <MenuItem value={'value'}>{'fixed discount'}</MenuItem>
          </TextField>
          {discountType === 'percent' ? (
            <TextField
              label={'value'}
              type={'number'}
              value={percentOff || ''}
              onChange={e => setPercentOff(Number(e.target.value))}
              sx={{ ml: 3 }}
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
          ) : (
            <TextField
              label={'value'}
              type={'number'}
              value={amountOff / 100 || ''}
              onChange={e => setAmountOff(Number(e.target.value) * 100)}
              sx={{ ml: 3 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          )}
        </Box>
        <Box display={'flex'}>
          <TextField
            select
            label={'recurrance'}
            value={duration || ''}
            onChange={i => setDuration(i.target.value as Duration)}
          >
            <MenuItem value={Duration.ONCE}>{'once'}</MenuItem>
            <MenuItem value={Duration.REPEATING}>{'fixed term'}</MenuItem>
            <MenuItem value={Duration.FOREVER}>{'ongoing'}</MenuItem>
          </TextField>
          {duration === Duration.REPEATING && (
            <TextField
              label={'duration'}
              type={'number'}
              value={durationInMonths || ''}
              onChange={e => setDurationInMonths(Number(e.target.value))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{`month${durationInMonths > 1 ? 's' : ''}`}</InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              sx={{ ml: 6 }}
            />
          )}
        </Box>
        <Box display={'flex'}>
          <TextField
            label={'max redemptions'}
            type={'number'}
            value={maxRedemptions || ''}
            onChange={e => setMaxRedemptions(Number(e.target.value))}
            sx={{ mr: 3 }}
          />
          <DatePicker
            label={'expiry date'}
            format={'dd/MM/yyyy'}
            onChange={d => {
              setRedeemBy(d as DateTime);
            }}
            value={redeemBy}
            sx={{ ml: 3 }}
          />
        </Box>
        <Grid mt={6} item xs={12} display={'flex'} justifyContent={'space-between'}>
          <Box>
            {coupon && (
              <Button
                variant="outlined"
                sx={{ justifySelf: 'start' }}
                onClick={() => {
                  destroy(coupon.id);
                  onClose();
                }}
              >
                Delete
              </Button>
            )}
          </Box>
          <Box>
            <Button
              disabled={disabled}
              variant="contained"
              onClick={() => {
                handleCreate();
              }}
            >
              {coupon ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Grid>
      </Card>
    </CustomModal>
  );
};
