import { Box, Card, Grid, IconButton, Typography } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { Cohort, useCohorts } from 'hooks/useCohorts';
import EditIcon from '@mui/icons-material/Edit';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { useState } from 'react';
import { CohortModal } from 'components/modals/cohort-modal';
import { AddButton } from 'components/shared/add-button';
import { CohortBadge } from 'components/shared/cohort-badge';

export const Cohorts = () => {
  const { school } = useSchoolContext();
  const { cardWidth } = useCustomTheme();
  const [cohort, setCohort] = useState<Cohort>();
  const cohortsResponse = useCohorts(school.id);
  const [open, setOpen] = useState(false);

  if (!cohortsResponse.data) {
    return <></>;
  }

  const cohorts = cohortsResponse.data;

  return (
    <>
      <CohortModal
        key={cohort?.id}
        cohort={cohort}
        open={open || !!cohort}
        onClose={() => {
          setCohort(undefined);
          setOpen(false);
        }}
      />
      <Grid container my={6} pt={4} mb={24} maxWidth={1200} justifyContent={'center'} alignContent={'center'}>
        {cohorts.map(c => (
          <Card key={c.id} sx={{ display: 'flex', borderRadius: 6, height: 50, my: 4, m: 3, width: cardWidth }}>
            <CohortBadge sx={{ my: 'auto', ml: 1, width: 45 }} colors={c.colors.split(',')} />
            <Box width={1} display={'flex'} justifyContent={'space-between'}>
              <Box ml={2} my={'auto'} alignItems={'space-between'}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography variant="h3">{c.name}</Typography>
                </Box>
              </Box>
              <Box mr={2} mt={2}>
                <IconButton size="small" onClick={() => setCohort(c)}>
                  <EditIcon sx={{ height: 18, width: 18 }} />
                </IconButton>
              </Box>
            </Box>
          </Card>
        ))}
        <AddButton label="Add grade" onClick={() => setOpen(true)} sx={{ m: 3, width: cardWidth }} />
      </Grid>
    </>
  );
};
