import { Box, Modal, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { ReactComponent as Smiley } from '../../../assets/smiley.svg';
import { ReactComponent as Cross } from '../../../assets/cross.svg';

export type ResponseType = undefined | 'success' | 'error';

type Props = {
  type: ResponseType;
  name?: string;
};

export const BookingResponse = ({ type, name }: Props) => {
  return (
    <>
      {type === 'success' && (
        <Modal open={!!type}>
          <Box display={'flex'} height={'100%'} width={'100%'} sx={{ backgroundColor: green['A700'] }}>
            <Box m={'auto'} color={'white'}>
              <Smiley />
              <Box mt={6}>
                <Typography variant="h1">Welcome Back</Typography>
                <Typography variant="h1">{`${name}!`}</Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      {type === 'error' && (
        <Modal open={!!type}>
          <Box display={'flex'} height={'100%'} width={'100%'} sx={{ backgroundColor: red[500] }}>
            <Box m={'auto'} color={'white'}>
              <Box m={'auto'} maxWidth={200}>
                <Cross />
              </Box>
              <Box mt={6} display={'flex'} justifyContent={'center'}>
                <Typography maxWidth={300} variant="h1">
                  Please sign up for a membership to attend this class{' '}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
