import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { Interval, Location } from 'utils/types';
import { Subject } from './useSubject';

export type Membership = {
  id: number;
  name: string;
  type: string;
  description: string;
  price: number;
  interval: Interval;
  frequency: number;
  minimumTerm: number;
  attendanceLimit: number;
  subjects: Subject[];
  locations: Location[];
  hidden: boolean;
  endDate?: string;
  limit?: number;
  groupLimit: number;
};

export const useMembership = (schoolId: number): UseQueryResult<Membership[]> => {
  const queryResponse = useQuery<any, AxiosError>(
    ['membership', schoolId],
    async () => {
      const response = await axios.get('/membership', { headers: { school: schoolId } });
      return response.data;
    },
    {
      enabled: !!schoolId,
    }
  );

  return queryResponse;
};
