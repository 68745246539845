import { useState } from 'react';
import { Greeting } from './greeting';
import { MemberDetails } from './member-details';
import { useUserContext } from 'components/context/user-context';
import { ChildrenDetails } from './children-details';
import { Box } from '@mui/system';
import { WaveBorder } from './wave-border';
import { useSchoolContext } from 'components/context/school-context';
import { contrast } from 'Theme';
import { Typography } from '@mui/material';
import { Footer } from './footer';
import { grey } from '@mui/material/colors';
import { useMutate } from 'hooks/useMutate';
import { EmergencyContact } from './emergency-contact';

export const SignupFlow = ({ children }: { children: JSX.Element }) => {
  const { user } = useUserContext();
  const { school } = useSchoolContext();
  const [greeting, setGreeting] = useState(true);
  const { update } = useMutate('user', {
    invalidate: ['user'],
  });

  const handleUpdate = () => {
    const data = {
      id: user.id,
      onboarding: { ...user.onboarding, intro: true },
    };
    update(data);
  };

  if (!user) {
    return null;
  }

  if (!user.onboarding.user) {
    return <>{greeting ? <Greeting onClick={() => setGreeting(false)} /> : <MemberDetails />}</>;
  }

  if (!user.onboarding.emergencyContact) {
    return <EmergencyContact />;
  }

  if (!user.onboarding.children) {
    return <ChildrenDetails />;
  }

  if (!user.onboarding.intro) {
    return (
      <Box display={'flex'} width={1} justifyContent={'center'}>
        <Box width={1}>
          <Box sx={{ display: 'flex', textAlign: 'center' }} height={'14vh'} bgcolor={grey[100]}>
            <Box width={100} m={'auto'} component={'img'} src={school.logo} borderRadius={'50%'} />
          </Box>
          <WaveBorder color={school.priColor} />
          <Box
            flexDirection={'column'}
            display={'flex'}
            width={1}
            textAlign={'center'}
            height={'80vh'}
            pb={40}
            bgcolor={school.priColor}
            color={contrast(school.priColor)}
          >
            <Box m={'auto'} p={10}>
              <Typography variant="h1">YOU'RE ALL SET!</Typography>
            </Box>
          </Box>
          <Footer onClick={handleUpdate} buttonText={'Finish >'} stage={5} />
        </Box>
      </Box>
    );
  }

  return children;
};
