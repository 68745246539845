import { useQueryState } from 'hooks/useQueryState';
import { SchoolSettings } from './school-settings';
import { Locations } from './locations/locations';
import { Subjects } from './subjects/subjects';
import { Grid } from '@mui/material';
import { TermsAndConditions } from './terms-and-conditions';
import { PageType, Tabs } from 'utils/types';
import { AuthWrapper } from 'components/auth-wrapper';

export const BusinessSettings = () => {
  const [query] = useQueryState();

  const currentTab = query[`${query.page} tab`];
  const tab = (n: number): boolean => currentTab === Tabs[PageType.business_settings][n];

  return (
    <AuthWrapper>
      <Grid container justifyContent={'center'} height={'94.5vh'} overflow={'scroll'}>
        {tab(0) && <SchoolSettings />}
        {tab(1) && <TermsAndConditions />}
        {tab(2) && <Locations />}
        {tab(3) && <Subjects />}
      </Grid>
    </AuthWrapper>
  );
};
